import styled from 'styled-components';
import { primary_color, project_read_more_color } from '../../style/constants';

const Button = styled.a`
  padding: 8px 20px 8px 20px;
  background-color: ${project_read_more_color};
  border-radius: 16px;
  color: white;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 32px;
  ${({ shrink }) => shrink && 'margin-top: 0px;'}

  :hover {
    cursor: pointer;
    color: ${primary_color};
  }
`;

export default Button;
