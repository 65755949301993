import styled from 'styled-components';
import { section_title_font } from '../../style/constants';

const H3 = styled.h3`
  margin: 0px;
  ${({ noMargin }) => noMargin ? '' : `margin-bottom: 16px`};
  font-size: 1.25em;
  font-family: '${section_title_font}', sans-serif;
  font-weight: bold;
`;

export default H3;
