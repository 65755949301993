import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Header from '../components/Header';
import Overview from '../components/Overview';
import MediaQuery from 'react-responsive';
import { ProcessFlyux, types } from '../components/process/ProcessFlyux';
import Project from '../components/Project';
import Research from '../components/pages/flyux/Research';
import StepModel from '../model/StepModel';
import { bulma_breakpoint_tablet, phone_width, responsive_desktop, responsive_table_and_less, tablet_width } from '../style/constants';
import Takeaways from '../components/Takeaways';
import TakeawayModel from '../model/TakeawayModel';
import AnalysisDetails from '../components/pages/flyux/AnalysisDetails';
import Design from '../components/pages/flyux/Design';
import UserTesting from '../components/pages/flyux/UserTesting';
import NextPrevious from '../components/widgets/NextPrevious';
import ProjectMain from '../components/layout/ProjectMain';
import Page from '../style/Page';
import IntroBar from '../components/widgets/IntroBar';
import { ProjectPart } from './onboarding';
import { DottedLineDown, DottedLineHorizontal } from '../components/widgets/DottedLineDown';

const ResearchContent = () => [
  (<>
    <FormattedMessage
      id="flyux.process.research.1"
      defaultMessage="Ran a <strong>competitive benchmark</strong> to complete the existing findings"
      description="research content paragraph #1"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.research.2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.research.3"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.research.4"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
];

const Analysis = () => [
  (<>
    <FormattedMessage
      id="flyux.process.analysis.1"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.analysis.2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
];

const Sketch = () => [
  (<>
    <FormattedMessage
      id="flyux.process.sketch.1"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.sketch.2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.sketch.3"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
];
const Testing = () => [
  (<>
    <FormattedMessage
      id="flyux.process.testing.1"
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.testing.2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
  (<>
    <FormattedMessage
      id="flyux.process.testing.3"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />
  </>),
];

const Diagram = styled.div`
  .diagram-title {
    grid-area: diagram-title;
  }

  ${responsive_table_and_less} {
    margin-right: 3em;
    margin-left: 3em;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows:
      auto
      auto
      auto
      auto
      auto
      auto
      auto
      auto;
    grid-template-areas: 
      "diagram-title diagram-title"
      "step-1 step-1"
      "step-1-to-2 step-1-to-2"
      "step-2 step-2"
      "step-2-to-3 step-2-to-3"
      "step-3 step-3"
      "step-3-to-4 step-4-to-3"
      "step-4 step-4";
  }

  ${responsive_desktop} {
    display: grid;
    grid-template-columns: auto 6em auto auto auto auto;
    grid-template-rows:
      auto
      auto
      5em
      auto
      auto
      auto
      auto
      auto;
    grid-template-areas:
      ". . diagram-title diagram-title diagram-title diagram-title"
      ". . step-1 step-1 step-1 ."
      ". . . step-1-to-2 . ."
      ". . . step-1-to-2 step-2 step-2"
      ". . . . step-2-to-3 ."
      ". . step-3 step-3 step-3 step-3"
      ". . . step-3-to-4 step-4-to-3 ."
      ". . . . step-4 step-4";
  }
`;


const FlyUXTablet = () => {
  const intl = useIntl();
  return (
    <>
      <Header
        title={intl.formatMessage({
          id: 'flyux.project.title',
          defaultMessage: 'Fly UX',
          description: 'flyux project page title',
        })}
        description={[
          intl.formatMessage({
            id: 'flyux.project.description',
            defaultMessage: 'Designing a flight booking website',
            description: 'flyux project page description',
          })
        ]}
        noColor
      />
      <IntroBar
        img="flyux/high_fid_2"
        alt="alt.flyux.intro.image"
        isTablet
      />
      <ProjectMain>
        <div className="mt-6">
            <Overview
              content={[
                intl.formatMessage({ id: "flyux.overview.1" }),
                intl.formatMessage({ id: "flyux.overview.2" }),
              ]}
            />
            <ProjectPart className="">
              <div className="empty-div-to-center-project-part"></div>
              <Project
                company={intl.formatMessage({
                  id: 'academic.project',
                  defaultMessage: 'Academic project',
                  description: 'flyux project company',
                })}
                role={intl.formatMessage({
                  id: 'flyux.role',
                  defaultMessage: 'UX Designer',
                  description: 'flyux project role',
                })}
                tools={intl.formatMessage({
                  id: 'paper.adobe',
                  defaultMessage: 'Paper & Adobe XD',
                  description: 'flyux project tools',
                })}
                year={2019}
              />
            </ProjectPart>
        </div>
        <ProcessFlyux
          Diagram={Diagram}
          name="flyux"
          steps={[
            new StepModel(types.research, intl.formatMessage({
              id: 'research.category.title',
              defaultMessage: 'Research',
              description: 'flyux process: research phase title',
            }), ResearchContent()),
            new StepModel(types.analysis, intl.formatMessage({
              id: 'analysis.category.title',
              defaultMessage: 'Analysis',
              description: 'flyux process: analysis phase title',
            }), Analysis()),
            new StepModel(types.sketch, intl.formatMessage({
              id: 'sketch.category.title',
              defaultMessage: 'Sketch & Prototype',
              description: 'flyux process: sketch & prototype phase title',
            }), Sketch()),
            new StepModel(types.testing, intl.formatMessage({
              id: 'testing.category.title',
              defaultMessage: 'User testing',
              description: 'flyux process: user testing phase title',
            }), Testing()),
          ]}
          links={[{ id: '1-to-2', alt: 'research.to.analysis' }, { id: '2-to-3', alt: 'analysis.to.sketch' }, { id: '3-to-4', alt: 'sketch.to.usability' }, { id: '4-to-3', alt: 'usability.to.sketch' }]}
        />
        <Research flavor="tablet" />
        <AnalysisDetails flavor="tablet" />
        <Design flavor="tablet" />
        <UserTesting flavor="tablet" />
        <Takeaways flavor="tablet"
          intro={''}
          items={[
            new TakeawayModel(
              intl.formatMessage({ id: 'flyux.takeaways.1.title' }),
              intl.formatMessage({ id: 'flyux.takeaways.1.description' }),
            )
          ]}
        />
        <NextPrevious
          previous={{
            title: 'onboarding.title',
            url: './onboarding',
          }}
          next={{
            title: 'whatsnew.title',
            url: './whatsnew',
          }}
        />
      </ProjectMain>
    </>
  );
};

const FlyUXDesktop = () => {
  const intl = useIntl();
  return (
    <>
      <Header
        title={intl.formatMessage({
          id: 'flyux.project.title',
          defaultMessage: 'Fly UX',
          description: 'flyux project page title',
        })}
        description={[
          intl.formatMessage({
            id: 'flyux.project.description',
            defaultMessage: 'Designing a flight booking website',
            description: 'flyux project page description',
          })
        ]}
        noColor
      />
      <IntroBar
        img="flyux/high_fid_2"
        alt="alt.flyux.intro.image"
      />
      <ProjectMain>
        <div className="columns mt-6">
            <div className="column"></div>
            <div className="column is-6">
              <Overview
                content={[
                  intl.formatMessage({ id: "flyux.overview.1" }),
                  intl.formatMessage({ id: "flyux.overview.2" }),
                ]}
              />
            </div>
            <ProjectPart className="column is-4">
              <div className="empty-div-to-center-project-part"></div>
              <Project
                company={intl.formatMessage({
                  id: 'academic.project',
                  defaultMessage: 'Academic project',
                  description: 'flyux project company',
                })}
                role={intl.formatMessage({
                  id: 'flyux.role',
                  defaultMessage: 'UX Designer',
                  description: 'flyux project role',
                })}
                tools={intl.formatMessage({
                  id: 'paper.adobe',
                  defaultMessage: 'Paper & Adobe XD',
                  description: 'flyux project tools',
                })}
                year={2019}
              />
              <div className="columns no-margin">
                <div className="column"></div>
                <DottedLineDown className="column"></DottedLineDown>
              </div>
            </ProjectPart>
            <div className="column"></div>
        </div>
        <div className="columns" style={{height: '0px'}}>
          <div className="column is-2"></div>
          <DottedLineHorizontal className="column" />
          <div className="column is-3"></div>
        </div>
        <ProcessFlyux
          Diagram={Diagram}
          name="flyux"
          steps={[
            new StepModel(types.research, intl.formatMessage({
              id: 'research.category.title',
              defaultMessage: 'Research',
              description: 'flyux process: research phase title',
            }), ResearchContent()),
            new StepModel(types.analysis, intl.formatMessage({
              id: 'analysis.category.title',
              defaultMessage: 'Analysis',
              description: 'flyux process: analysis phase title',
            }), Analysis()),
            new StepModel(types.sketch, intl.formatMessage({
              id: 'sketch.category.title',
              defaultMessage: 'Sketch & Prototype',
              description: 'flyux process: sketch & prototype phase title',
            }), Sketch()),
            new StepModel(types.testing, intl.formatMessage({
              id: 'testing.category.title',
              defaultMessage: 'User testing',
              description: 'flyux process: user testing phase title',
            }), Testing()),
          ]}
          links={[{ id: '1-to-2', alt: 'research.to.analysis' }, { id: '2-to-3', alt: 'analysis.to.sketch' }, { id: '3-to-4', alt: 'sketch.to.usability' }, { id: '4-to-3', alt: 'usability.to.sketch' }]}
        />
        <div className="columns" style={{height: '0px'}}>
          <div className="column is-2"></div>
          <DottedLineHorizontal className="column" />
          <div className="column is-3"></div>
        </div>
        <Research />
        <AnalysisDetails />
        <Design />
        <UserTesting />
        <Takeaways
          intro={''}
          items={[
            new TakeawayModel(
              intl.formatMessage({ id: 'flyux.takeaways.1.title' }),
              intl.formatMessage({ id: 'flyux.takeaways.1.description' }),
            )
          ]}
        />
        <NextPrevious
          previous={{
            title: 'onboarding.title',
            url: './onboarding',
          }}
          next={{
            title: 'whatsnew.title',
            url: './whatsnew',
          }}
        />
      </ProjectMain>
    </>
  );
};

const FlyUX = () => {
  return (
    <Page>
      <MediaQuery minWidth={bulma_breakpoint_tablet}>
        <FlyUXDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
        <FlyUXTablet />
      </MediaQuery>
    </Page>
  );
};

export default FlyUX;
