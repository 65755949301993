import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { phone_width, primary_color, responsive_desktop, responsive_tablet_only, responsive_table_and_less, tablet_width } from '../../style/constants';

const Styled = styled.div`
  align-items: center;
  border: 4px solid ${primary_color};
  z-index: 4;
  padding: 32px 32px 32px 32px;
  border-radius: 4px;

  ${responsive_desktop} {
    display: grid;
    grid-row: 4;
    grid-template-columns: 40% auto;

    .project-label, .description {
      margin-bottom: 16px;
    }
  }

  ${responsive_table_and_less} {
    display: grid;
    grid-row: 8;
    grid-template-columns: auto;

    .project-label {
      margin-bottom: 0px;
    }

    .description {
      margin-bottom: 16px;
    }
  }
`;

const Cartouche = ({ company, role, tools, year }) => (
  <Styled className="cartouche-container">
    <strong className="project-label">
      <FormattedMessage
        id="project.company.title"
        defaultMessage="Company"
        description="project company title"
      />
    </strong>
    <span className="description">{company}</span>
    <strong className="project-label">
      <FormattedMessage
        id="project.role.title"
        defaultMessage="Role"
        description="project role title"
      />
    </strong>
    <span className="description">{role}</span>
    <strong className="project-label">
      <FormattedMessage
        id="project.tools.title"
        defaultMessage="Tools"
        description="project tools title"
      />
    </strong>
    <span className="description">{tools}</span>
    <strong className="project-label">
      <FormattedMessage
        id="project.year.title"
        defaultMessage="Year"
        description="project year title"
      />
    </strong>
    <span className="description">{year}</span>
  </Styled>
);

export default Cartouche;
