import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { bulma_breakpoint_tablet, phone_width, process_step_arrow_size, process_step_arrow_size_responsive, responsive_desktop, responsive_table_and_less, tablet_width } from '../../style/constants';
import MediaQuery from 'react-responsive';
import TopOnTop from '../layout/TopOnTop';
import CategoryTitle from '../widgets/CategoryTitle';
import { DottedLineDown } from '../widgets/DottedLineDown';
import Step from './Step'

export const types = {
  research: 'research',
  analysis: 'analysis',
  sketch: 'sketch',
  testing: 'testing',
};

const StyledSection = styled.section`
  ${responsive_table_and_less} {
    margin-top: 3em;
    
    .step-link {
      display: none;
    }

    .step-link-straight, .step-link-straight.step-1-to-2 {
      visibility: visible;
      justify-self: center;
    }
  }

  ${responsive_desktop} {
    .step-link {
      visibility: visible;
    }

    .step-link-straight {
      display: none;
    }
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  h2 {
    margin-bottom: 32px;
  }

  .step-1, step-3 {
    animation: fadein 1s;
  }

  .step-2, step-4 {
    animation: fadein 1s;
  }

  .step-link {
    width: ${process_step_arrow_size};
    margin: 16px;
  }

  .step-link-straight {
    width: ${process_step_arrow_size_responsive};
    margin: 16px;
  }

  .step-1-to-2 {
    grid-area: step-1-to-2;
    justify-self: flex-end;
  }

  .step-2-to-3 {
    grid-area: step-2-to-3;
    transform: scale(-1, 1);
  }

  .step-3-to-4 {
    grid-area: step-3-to-4;
  }

  .step-4-to-3 {
    grid-area: step-4-to-3;
    transform: scale(-1, -1);
  }
`;

export const ProcessFlyux = ({ name, steps, links, Diagram }) => {
  const intl = useIntl();
  return (
    <TopOnTop>
      <MediaQuery minWidth={bulma_breakpoint_tablet}>
        <div className="columns background">
          <div className="column is-2"></div>
          <DottedLineDown />
          <div className="column is-6"></div>
        </div>
        <div className="columns foreground mt-2 mb-6">
          <div className="column"></div>
          <div className="column is-10">
            <StyledSection>
              <Diagram className={`diagram ${name}`}>
                <CategoryTitle className="diagram-title" noMargin>
                  <FormattedMessage
                    id="process.title"
                    defaultMessage="Process"
                    description="process category title"
                  />
                </CategoryTitle>
                {steps.map((step, i) => (<Step index={i+1} type={step.type} title={step.title} content={step.content} />))}
                {links.map((link) => (
                  <>
                    <img alt={intl.formatMessage({ id: `alt.flyux.process.arrow.${link.alt}` })} className={`step-link step-${link.id}`} src="./images/arrow.svg" />
                    <img alt={intl.formatMessage({ id: `alt.flyux.process.arrow.${link.alt}` })} className={`step-link-straight step-${link.id}`} src="./images/arrow-straight.svg" />
                  </>
                ))}
              </Diagram>
            </StyledSection>
          </div>
          <div className="column"></div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
          <StyledSection>
            <Diagram className={`diagram ${name}`}>
              <CategoryTitle className="diagram-title" noMargin>
                <FormattedMessage
                  id="process.title"
                  defaultMessage="Process"
                  description="process category title"
                />
              </CategoryTitle>
              {steps.map((step, i) => (<Step index={i+1} type={step.type} title={step.title} content={step.content} />))}
              {links.map((link) => (
                <>
                  <img alt={intl.formatMessage({ id: `alt.flyux.process.arrow.${link.alt}` })} className={`step-link step-${link.id}`} src="./images/arrow.svg" />
                  <img alt={intl.formatMessage({ id: `alt.flyux.process.arrow.${link.alt}` })} className={`step-link-straight step-${link.id}`} src="./images/arrow-straight.svg" />
                </>
              ))}
            </Diagram>
          </StyledSection>
      </MediaQuery>
    </TopOnTop>
  );
}

