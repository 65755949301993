import { FormattedMessage } from "react-intl"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import ListItem from "../../widgets/ListItem"
import Paragraph from "../../widgets/Paragraph"
import { DottedBackground } from "./ResearchDetails"

const SketchDetails = ({ flavor }) => {
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle>
            <FormattedMessage
              id="sketch.only.category.title"
            />
          </CategoryTitle>
          <section>
            <div className="column is-8" style={{ margin: '0px', padding: '0px' }}>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.sketch.description.1"
                  values={{
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.sketch.description.2"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.sketch.description.3"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </Paragraph>
            </div>
          </section>
        </div>
      </div>
    );
  }
  return (
    <TopOnTop>
      <DottedBackground size={10} />
      <div className="foreground columns mt-4">
        <div className="column"></div>
        <div className="column is-10">
          <CategoryTitle>
            <FormattedMessage
              id="sketch.only.category.title"
            />
          </CategoryTitle>
          <section className="mb-6">
            <div className="column is-8" style={{ margin: '0px', padding: '0px' }}>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.sketch.description.1"
                  values={{
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.sketch.description.2"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.sketch.description.3"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </Paragraph>
            </div>
          </section>
        </div>
      </div>
    </TopOnTop>
  )
}

export default SketchDetails
