import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { bulma_breakpoint_tablet, footer_background_color, primary_color, project_read_more_color } from '../../style/constants';

const StyledFooter = styled.footer`
  ${({ noColor }) => !noColor && `background-color: ${footer_background_color};`}
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${project_read_more_color};

  a {
    margin-left: 4px;
    margin-right: 4px;
  }

  a:hover {
    color: ${primary_color};
  }
`;

const Footer = ({ noColor }) => (
  <>
    <MediaQuery minWidth={bulma_breakpoint_tablet}>
      <StyledFooter className="mt-6 py-4" noColor={noColor}>
        ©pakajo & co - 2021 | <a href="/legal"><FormattedMessage id="legal.link" defaultMessage="Mentions légales" /></a> | <a href="/confidentiality"><FormattedMessage id="confidentiality.link" defaultMessage="Politique de confidentialité du site" /></a>
      </StyledFooter>
    </MediaQuery>
    <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
      <StyledFooter className="is-flex is-flex-direction-column mt-6 py-3" noColor={noColor}>
        <span className="my-2">©pakajo & co - 2021</span>
        <a href="/legal" className="mb-2"><FormattedMessage id="legal.link" defaultMessage="Mentions légales" /></a>
        <a href="/confidentiality" className="mb-2"><FormattedMessage id="confidentiality.link" defaultMessage="Politique de confidentialité du site" /></a>
      </StyledFooter>
    </MediaQuery>
  </>
);

export default Footer;
