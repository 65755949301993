import styled from 'styled-components';

const AnimatedImage = styled.img`
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  animation: fadein 1s;
`;

const Blob = ({ page, position }) => (
  <AnimatedImage
    className={`blob ${position}`}
    src={`./images/blobs/${page}_${position}.svg`}
    alt="blob"
  />
);

export default Blob;
