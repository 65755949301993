import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ImageViewer from 'react-simple-image-viewer';

import H3 from '../../widgets/h3';
import CategoryTitle from '../../widgets/CategoryTitle';
import Paragraph from '../../widgets/Paragraph';
import ImagesMosaic from '../../widgets/ImagesMosaic';
import TopOnTopImages from '../../widgets/TopOnTopImages';
import SideText from '../../widgets/SideText';
import TextAndSideImage from '../../layout/TextAndSideImage';
import Image from '../../widgets/Image';
import { DottedLineDown, DottedLineHorizontal } from '../../widgets/DottedLineDown';
import TopOnTop from '../../layout/TopOnTop';

const Design = ({ flavor }) => {
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    './images/flyux/flow_1.png',
    './images/flyux/flow_2.png',
    './images/flyux/flow_3.png',
    './images/flyux/flow_4.png',
    './images/flyux/flow_5.png',
    './images/flyux/flow_6.png',
  ];
  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (flavor === 'tablet') {
    return (
      <>
        <div className="my-6 mx-6">
          <CategoryTitle>
            <FormattedMessage id="design.category.title" />
          </CategoryTitle>
          <Paragraph>
            <FormattedMessage
              id="flyux.design.intro.1"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="flyux.design.intro.2"
            />
          </Paragraph>
          <TextAndSideImage style={{ marginTop: '3em' }}>
            <SideText>
              <H3>
                <FormattedMessage
                  id="flow.diagram.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="flyux.flow.diagram.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.flow.diagram.2"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.flow.diagram.3"
                />
              </Paragraph>
            </SideText>
            <ImagesMosaic margin={24}>
              <Image
                alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.1' })}
                src="./images/flyux/flow_1.png"
                onClick={() => openImageViewer(0)}
              />
              <Image
                alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.2' })}
                src="./images/flyux/flow_2.png"
                onClick={() => openImageViewer(1)}
              />
              <Image
                alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.3' })}
                src="./images/flyux/flow_3.png"
                onClick={() => openImageViewer(2)}
              />
              <Image
                alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.4' })}
                src="./images/flyux/flow_4.png"
                onClick={() => openImageViewer(3)}
              />
              <Image
                alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.5' })}
                src="./images/flyux/flow_5.png"
                onClick={() => openImageViewer(4)}
              />
              <Image
                alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.6' })}
                src="./images/flyux/flow_6.png"
                onClick={() => openImageViewer(5)}
              />
            </ImagesMosaic>
          </TextAndSideImage>
        </div>
        <div className="mx-6">
          <TextAndSideImage invert  style={{ marginTop: '3em' }}>
            <SideText withMargin={false}>
              <H3>
                <FormattedMessage
                  id="high.fidelity.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="flyux.high.fidelity.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.high.fidelity.2"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.high.fidelity.3"
                />
              </Paragraph>
              <Paragraph>
                <a className="basic-link" href="https://xd.adobe.com/view/801cad96-ba77-4777-54e4-01697bf0b617-f62d/screen/59500c4b-7cc5-4ef9-ab14-faf6407f94db">
                  <FormattedMessage
                    id="flyux.high.fidelity.4"
                  />
                </a>
              </Paragraph>
            </SideText>
            <TopOnTopImages>
              <img alt={intl.formatMessage({ id: 'alt.flyux.high.fidelity.screenshot.1' })} className="hf-1" src="./images/flyux/high_fid_1.png" />
              <img alt={intl.formatMessage({ id: 'alt.flyux.high.fidelity.screenshot.2' })} className="hf-2" src="./images/flyux/high_fid_2.png" />
            </TopOnTopImages>
          </TextAndSideImage>
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside
            onClose={closeImageViewer}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div className="columns" style={{height: '0px'}}>
        <div className="column is-2"></div>
        <DottedLineHorizontal className="column" />
        <div className="column is-2"></div>
      </div>
      <TopOnTop>
        <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-10"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
        <div className="foreground columns mt-4 mb-4">
          <div className="column"></div>
          <div className="column is-10">
            <CategoryTitle>
              <FormattedMessage id="design.category.title" />
            </CategoryTitle>
            <Paragraph>
              <FormattedMessage
                id="flyux.design.intro.1"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="flyux.design.intro.2"
              />
            </Paragraph>
            <TextAndSideImage className="mt-6">
              <SideText>
                <H3>
                  <FormattedMessage
                    id="flow.diagram.title"
                  />
                </H3>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.flow.diagram.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.flow.diagram.2"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.flow.diagram.3"
                  />
                </Paragraph>
              </SideText>
              <ImagesMosaic margin={24}>
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.1' })}
                  src="./images/flyux/flow_1.png"
                  onClick={() => openImageViewer(0)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.2' })}
                  src="./images/flyux/flow_2.png"
                  onClick={() => openImageViewer(1)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.3' })}
                  src="./images/flyux/flow_3.png"
                  onClick={() => openImageViewer(2)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.4' })}
                  src="./images/flyux/flow_4.png"
                  onClick={() => openImageViewer(3)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.5' })}
                  src="./images/flyux/flow_5.png"
                  onClick={() => openImageViewer(4)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.flow.diagram.6' })}
                  src="./images/flyux/flow_6.png"
                  onClick={() => openImageViewer(5)}
                />
              </ImagesMosaic>
            </TextAndSideImage>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
      <div className="columns" style={{height: '0px'}}>
        <div className="column is-2"></div>
        <DottedLineHorizontal className="column" />
        <div className="column is-2"></div>
      </div>
      <TopOnTop>
        <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-2"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
        <div className="foreground columns mt-6">
          <div className="column"></div>
          <div className="column is-10">
            <TextAndSideImage invert>
              <SideText withMargin={false}>
                <H3>
                  <FormattedMessage
                    id="high.fidelity.title"
                  />
                </H3>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.high.fidelity.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.high.fidelity.2"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.high.fidelity.3"
                  />
                </Paragraph>
                <Paragraph>
                  <a className="basic-link" href="https://xd.adobe.com/view/801cad96-ba77-4777-54e4-01697bf0b617-f62d/screen/59500c4b-7cc5-4ef9-ab14-faf6407f94db">
                    <FormattedMessage
                      id="flyux.high.fidelity.4"
                    />
                  </a>
                </Paragraph>
              </SideText>
              <TopOnTopImages>
                <img alt={intl.formatMessage({ id: 'alt.flyux.high.fidelity.screenshot.1' })} className="hf-1" src="./images/flyux/high_fid_1.png" />
                <img alt={intl.formatMessage({ id: 'alt.flyux.high.fidelity.screenshot.2' })} className="hf-2" src="./images/flyux/high_fid_2.png" />
              </TopOnTopImages>
            </TextAndSideImage>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default Design;
