export const primary_color = '#F25F5C'
export const secondary_color = '#247BA0';

export const project_category_odd_color = `${primary_color}10`;
export const header_background_color = `${secondary_color}25`;
export const footer_background_color = header_background_color;
export const project_work_type_color = primary_color;
export const project_read_more_color = secondary_color;
export const presentation_name_color = primary_color;
export const hover_link = primary_color;
export const selected_link = hover_link;
export const project_subtitles_colors = secondary_color;

export const burger_button_color = "#373a47";
export const burger_background = secondary_color;
export const burger_hover_color = primary_color;
export const burger_cross_background = burger_button_color;

export const nda_image_size = '3em';
export const process_step_arrow_size = '9em';
export const process_step_arrow_size_responsive = '2em';
export const process_step_icon_size = '3em';
export const process_step_title_size = '1.5em';
export const process_step_underline_size = '9em';
export const research_image_size = '6em';
export const big_mosaic_image_size = '8em';
export const research_screenshot_size = '45em';
export const project_work_type_size = '1.5em';
export const project_work_name_size = '1.5em';
export const project_image_size = '20vw';

export const process_step_title_font = 'Ubuntu';
export const project_work_type_font = 'Ubuntu';
export const body_font = 'Lato';
export const nav_font = 'Ubuntu';
export const project_category_font = 'Ubuntu';
export const section_title_font = 'Lato';
export const project_subtitle_font = 'Ubuntu';
export const page_title_font = 'Ubuntu';

export const phone_width = 501; // 320
export const tablet_width = 502; // 321
export const laptop_width = 802;

export const bulma_breakpoint_tablet = 769;

export const responsive_phone = `@media screen and (max-width: ${phone_width}px)`;
export const responsive_desktop = `@media screen and (min-width: ${bulma_breakpoint_tablet}px)`;
export const responsive_tablet_only = `@media screen and (min-width: ${phone_width + 1}px) and (max-width: ${tablet_width*2 + 1}px)`;
export const responsive_table_and_less = `@media screen and (max-width: ${bulma_breakpoint_tablet - 1}px)`;
