import styled from 'styled-components';

const StyledContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

const BlobContainer = ({ className, children }) => (
  <StyledContainer className={`blob-container ${className}`}>
    {children}
  </StyledContainer>
);

export default BlobContainer;
