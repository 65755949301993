import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { phone_width } from '../../style/constants';
import { useLocale } from '../contexts/LocaleContext';

const Styled = styled.div`
  .languages {
    justify-self: flex-end;
  }
`;

const BurgerMenu = () => {
  const location = useLocation();
  const { setLocale } = useLocale();
  const [isOpen, setOpen] = useState(false);
  return (
    <MediaQuery maxWidth={phone_width}>
      <Styled className="burger-menu">
        <Menu
          outerContainerId="app"
          pageWrapId="page-wrap"
          width="100%"
          right
          isOpen={isOpen}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          <Link className="bm-item" to="/" onClick={() => setOpen(false)}>
            <FormattedMessage
              id="nav.work"
              defaultMessage="Work"
              description="navbar link: work"
            />
          </Link>

          <Link className="bm-item" to="/about" onClick={() => setOpen(false)}>
            <FormattedMessage
              id="nav.about"
              defaultMessage="About"
              description="navbar link: about"
            />
          </Link>

          <div className="languages">
            <Link
              to={`${location.pathname}`}
              locale="fr"
              className="french"
              onClick={() => setLocale('fr')}
            >
              FR
            </Link>
            /
            <Link
              to={`${location.pathname}`}
              locale="en-US"
              onClick={() => setLocale('en')}
            >
              EN
            </Link>
          </div>
        </Menu>
      </Styled>
    </MediaQuery>
  );
};

export default BurgerMenu;
