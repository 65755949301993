import styled from 'styled-components';

const ListItem = styled.li`
  :before {
    content: "•";
    margin-right: 8px;
  }
`;

export default ListItem;
