import { FormattedMessage } from "react-intl"
import styled from "styled-components"
import { responsive_desktop, responsive_table_and_less, secondary_color } from "../../../style/constants"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import ListItem from "../../widgets/ListItem"
import Paragraph from "../../widgets/Paragraph"
import { SideBySide } from "./PrototypeDetails"
import { DottedBackground } from "./ResearchDetails"

const Table = styled.div`
  display: grid;

  ${responsive_table_and_less} {
    grid-template-areas:
    'painpointtitle'
    'pp-1'
    'pp-2'
    'pp-3'
    'improvementtitle'
    'imp-1'
    'imp-2'
    'imp-3';

    > div {
      border: 0.75px solid ${secondary_color};
      padding: 1em;

      &:nth-child(even) {
        border-top: none;
      }
      &:nth-child(odd) {
        border-top: none;
      }
      &:nth-child(1), &:nth-child(5) {
        margin-top: 4em;
        border-top: 0.75px solid ${secondary_color};
      }
    }
  }

  ${responsive_desktop} {
    grid-template-areas:
      'painpointtitle improvementtitle'
      'pp-1 imp-1'
      'pp-2 imp-2'
      'pp-3 imp-3';
    margin-right: 6em;
    background-color: white;

    > div {
      border: 0.75px solid ${secondary_color};
      padding: 1em;

      &:nth-child(1) {
        border-right: none;
        border-bottom: none;
      }
      &:nth-child(2) {
        border-right: none;
        border-bottom: none;
      }
      &:nth-child(3) {
        border-right: none;
        border-bottom: none;
      }
      &:nth-child(4) {
        border-right: none;
      }
      &:nth-child(5) {
        border-bottom: none;
      }
      &:nth-child(6) {
        border-bottom: none;
      }
      &:nth-child(7) {
        border-bottom: none;
      }
    }
  }

`;

const TableTitle = styled.div`
  grid-area: ${({ area }) => area};
  color: ${secondary_color};
  font-weight: bold;
  text-align: center;
`;

const TableCell = styled.div`
  grid-area: ${({ area }) => area};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PainPointsTable = ({}) => {
  return (
    <Table>
      <TableTitle area="painpointtitle">
        <FormattedMessage id="onboarding.user.testing.pain.points" />
      </TableTitle>
      <TableCell area="pp-1">
        <div>
          <FormattedMessage
            id="onboarding.user.testing.pain.points.1"
            values={{
              strong: chunks => <strong>{chunks}</strong>,
            }}    
          />
        </div>
      </TableCell>
      <TableCell area="pp-2">
        <div>
          <FormattedMessage
            id="onboarding.user.testing.pain.points.2"
            values={{
              strong: chunks => <strong>{chunks}</strong>,
            }}    
          />
        </div>
      </TableCell>
      <TableCell area="pp-3">
        <div>
          <FormattedMessage
            id="onboarding.user.testing.pain.points.3"
            values={{
              strong: chunks => <strong>{chunks}</strong>,
            }}    
          />
        </div>
      </TableCell>
      <TableTitle area="improvementtitle">
        <FormattedMessage id="onboarding.user.testing.improvements" />
      </TableTitle>
      <TableCell area="imp-1">
        <div>
          <FormattedMessage
            id="onboarding.user.testing.improvements.1"
            values={{
              strong: chunks => <strong>{chunks}</strong>,
              ul: chunks => <ul>{chunks}</ul>,
              li: chunks => <ListItem>{chunks}</ListItem>,
            }}    
          />
        </div>
      </TableCell>
      <TableCell area="imp-2">
        <div>
          <FormattedMessage
            id="onboarding.user.testing.improvements.2"
            values={{
              strong: chunks => <strong>{chunks}</strong>,
            }}    
          />
        </div>
      </TableCell>
      <TableCell area="imp-3">
        <div>
          <FormattedMessage
            id="onboarding.user.testing.improvements.3"
            values={{
              strong: chunks => <strong>{chunks}</strong>,
            }}    
          />
        </div>
      </TableCell>
    </Table>
  );
}

const UserTestingDetails = ({ flavor }) => {
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle>
            <FormattedMessage
              id="user.testing.category.title"
            />
          </CategoryTitle>
          <Paragraph>
            <FormattedMessage
              id="onboarding.user.testing.description.1"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="onboarding.user.testing.description.2"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="onboarding.user.testing.description.3"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
              }}
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="onboarding.user.testing.description.4"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                em: chunks => <u>{chunks}</u>,
                ul: chunks => <ul>{chunks}</ul>,
                li: chunks => <ListItem>{chunks}</ListItem>
              }}
            />
          </Paragraph>
          <PainPointsTable flavor={flavor} />
        </div>
      </div>
    )
  }
  return (
    <TopOnTop>
      <DottedBackground size={10} />
      <div className="foreground columns mt-4">
        <div className="column"></div>
        <div className="column is-10">
          <CategoryTitle>
            <FormattedMessage
              id="user.testing.category.title"
            />
          </CategoryTitle>
          <SideBySide>
            <section>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.user.testing.description.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.user.testing.description.2"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.user.testing.description.3"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.user.testing.description.4"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                    em: chunks => <u>{chunks}</u>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
            </section>
            <PainPointsTable />
          </SideBySide>
        </div>
      </div>
    </TopOnTop>
  )
}

export default UserTestingDetails
