import { FormattedMessage, useIntl } from "react-intl"
import { secondary_color } from "../../../style/constants"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import { DottedLineDown } from "../../widgets/DottedLineDown"
import ListItem from "../../widgets/ListItem"
import Paragraph from "../../widgets/Paragraph"
import { SideBySide } from "../onboarding/PrototypeDetails"

export const DottedBackground = ({ size }) => (
  <div className="background">
    <div className="columns" style={{ height: '100%' }}>
      <div className={`column is-${size ? size : 9}`}></div>
      <DottedLineDown className="column" />
    </div>
  </div>
)

const PrototypeDetails = ({ flavor }) => {
  const intl = useIntl();
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle>
            <FormattedMessage id="prototype.category.title" />
          </CategoryTitle>
          <SideBySide>
            <section className="mb-6">
              <Paragraph>
                <FormattedMessage id="whatsnew.prototype.description.1" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.prototype.description.2" />
              </Paragraph>
              <ul>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.2.a" /></ListItem>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.2.b" /></ListItem>
              </ul>
            </section>
            <img
              style={{ border: `1px solid ${secondary_color}` }}
              alt={intl.formatMessage({ id: 'alt.whatsnew.prototype.1' })}
              src="./images/whatsnew/prototype_1.png"
            />
          </SideBySide>
          <img
            style={{ border: `1px solid ${secondary_color}` }}
            alt={intl.formatMessage({ id: 'alt.whatsnew.prototype.2' })}
            src="./images/whatsnew/prototype_2.png"
          />
        </div>
      </div>
    )
  }
  return (
    <>
      <TopOnTop>
        <DottedBackground size={10} />
        <div className="foreground mt-4">
          <div className="columns">
            <div className="column"></div>
            <div className="column is-10">
              <CategoryTitle>
                <FormattedMessage
                  id="prototype.category.title"
                />
              </CategoryTitle>
            </div>
            <div className="column"></div>
          </div>
          <div className="columns">
            <div className="column"></div>
            <section className="column is-10">
              <Paragraph>
                <FormattedMessage id="whatsnew.prototype.description.1" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.prototype.description.2" />
              </Paragraph>
              <ul>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.2.a" /></ListItem>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.2.b" /></ListItem>
              </ul>
            </section>
            <div className="column"></div>
          </div>
          <div className="columns">
            <div className="column"></div>
            <div className="column is-5">
              <img
                style={{ border: `1px solid ${secondary_color}` }}
                src="./images/whatsnew/prototype_1.png"
                alt={intl.formatMessage({ id: 'alt.whatsnew.prototype.1' })}
              />
            </div>
            <div className="column is-5">
              <img
                style={{ border: `1px solid ${secondary_color}` }}
                src="./images/whatsnew/prototype_2.png"
                alt={intl.formatMessage({ id: 'alt.whatsnew.prototype.2' })}
              />
            </div>
            <div className="column"></div>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
    </>
  )
}

export default PrototypeDetails
