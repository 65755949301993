import { useIntl } from "react-intl";

const Star = ({ color = 'red', number }) => {
  const intl = useIntl();
  return (
    <img
      alt={intl.formatMessage({ id: 'alt.star.image' })}
      className={`star ${number}`}
      src={`./images/stars/${color}.svg`}
    />
  );
}

export default Star;
