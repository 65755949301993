import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import OverviewModel from '../model/OverviewModel';
import { responsive_tablet_only, responsive_table_and_less } from '../style/constants';
import StyledProjectCategory from '../style/ProjectCategoryStyle';
import CategoryTitle from './widgets/CategoryTitle';
import H3 from './widgets/h3';

const containerSpaces = '16px';

const StyledContainer = styled(StyledProjectCategory)`
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  margin-bottom: ${containerSpaces};
  padding-bottom: ${containerSpaces};

  animation: fadein 1s;

  section > ul > li {
    display: flex;
  }

  ${responsive_table_and_less} {
    margin-left: 3em;
    margin-right: 3em;
    width: unset;

    :last-child {
      margin-bottom: 0px;
    }
    div:last-child {
      margin-bottom: 0px;
    }
  }
`;

const SubContainer = styled.div`
  align-self: baseline;
`;

const Overview = ({ content }) => (
  <StyledContainer>
    <CategoryTitle>
      <FormattedMessage
        id="overview.title"
      />
    </CategoryTitle>
    {content.map((item) => (item instanceof OverviewModel
      ? (
        <SubContainer style={{ marginBottom: (item.removeMargin ? '0px' : '56px') }}>
          <H3>{item.title}</H3>
          {item.renderContent()}
        </SubContainer>
      ) : (<SubContainer style={{ marginBottom: '0px', }}>{item}</SubContainer>)))}
  </StyledContainer>
);

export default Overview;
