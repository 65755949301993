import styled from 'styled-components';

const TopOnTop = styled.div`
    display: grid;
    grid-template-areas: "main-space";

    .background {
        grid-area: main-space;
    }

    .foreground {
        grid-area: main-space;
        z-index: 1;
    }
`;

export default TopOnTop;
