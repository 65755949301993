import styled from 'styled-components';
import { bulma_breakpoint_tablet, header_background_color, page_title_font, phone_width, secondary_color } from '../style/constants';
import { burgerHeight, burgerTop } from '../style/Page';
import BurgerMenu from './navigation/BurgerMenu';
import Nav from './navigation/Nav';
import MediaQuery from 'react-responsive';

const paddingHeader = 88;
export const placeholderHeight = 104;

const StyledHeader = styled.header`
  padding-bottom: 20px;

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: ${phone_width}px) {
    width: 100%;
  }
`;

const StyledTitle = styled.div`
  z-index: 1;

  h1 {
    margin-top: 0px;
    font-family: '${page_title_font}', sans-serif;
  }

  p {
    font-weight: 300;
    font-size: 2em;
  }
`;

const StyledContainer = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  ${({ noColor }) => !noColor && `background-color: ${header_background_color};`}
  padding-top: ${paddingHeader}px;
  padding-bottom: 20px;
  z-index: 1;

  @media screen and (max-width: ${phone_width}px) {
    margin-top: ${burgerHeight + burgerTop + 16}px;
  }
`;

const StyledName = styled.img`
  width: 209px;
  height: intrisic;
  object-fit: cover;
  align-self: center;
  padding-left: 3px;
  margin-top: 3px;
`;

const RowContainer = styled.div`
  display: flex;
`;

const Centerize = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  ${({ border }) => border && `border-bottom: 1px solid ${secondary_color}20;`}

  @media screen and (max-width: ${phone_width}px) {
    position: fixed;
    top: 0px;
    height: ${burgerHeight + burgerTop + 16}px;
    justify-content: center;
    background-color: white;
    z-index: 1000;
    border-bottom: 2px solid ${header_background_color};
    width: 100%;
  }
`;

const Header = ({ title, description, noColor }) => (
  <StyledHeader>
    <MediaQuery minWidth={bulma_breakpoint_tablet}>
      <Centerize border={noColor} className="columns">
        <div className="column"></div>
        <RowContainer className="column is-10">
          <a href="/" style={{width: '254px', height: '24px'}}>
            <StyledName src="./images/name.png" alt="name" />
          </a>
          <Nav />
          <BurgerMenu />
        </RowContainer>
        <div className="column"></div>
      </Centerize>
      <div className="columns">
        <div className="column"></div>
        <StyledContainer noColor={noColor} className="column is-10">
          <StyledTitle>
            <h1>{title}</h1>
            {description.map((desc) => (<p>{desc}</p>))}
          </StyledTitle>
        </StyledContainer>
        <div className="column"></div>
      </div>
    </MediaQuery>
    <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
      <Centerize border={noColor}>
        <RowContainer className="mx-4 px-5">
          <a href="/" style={{width: '254px', height: '24px'}}>
            <StyledName src="./images/name.png" alt="name" />
          </a>
          <Nav />
          <BurgerMenu />
        </RowContainer>
      </Centerize>
      <div>
        <StyledContainer noColor={noColor} className="mx-4 px-5">
          <StyledTitle>
            <h1>{title}</h1>
            {description.map((desc) => (<p>{desc}</p>))}
          </StyledTitle>
        </StyledContainer>
      </div>
    </MediaQuery>
  </StyledHeader>
);

export default Header;
