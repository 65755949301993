import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Section from '../layout/Section';
import CategoryTitle from '../widgets/CategoryTitle';
import Skill from './Skill';

const skills = [
  {
    title: 'skills.set.1',
    icon: 'research',
    content: [
      'Depth interview',
      'Online survey',
      'Competitive benchmark',
    ],
  }, {
    title: 'skills.set.2',
    icon: 'sketch',
    content: [
      'User flow',
      'Navigation flow',
      'Interaction design',
      'Wireframing',
    ],
  }, {
    title: 'skills.set.3',
    icon: 'pen',
    content: [
      'Microcopy',
      'Information architecture',
      'Online help',
      'Terminology',
    ],
  }
];

const StyledSkillList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Skills = () => (
  <div className="mt-6">
    <CategoryTitle>
      <FormattedMessage
        id="skills.title"
        defaultMessage="Core Skills"
        description="core skills' title"
      />
    </CategoryTitle>
    <Section>
      <StyledSkillList>
        {skills.map((skill) => (
          <Skill
            title={skill.title}
            icon={skill.icon}
            content={skill.content}
          />)
        )}
      </StyledSkillList>
    </Section>
  </div>
);

export default Skills;
