import './App.scss';
import { LocaleProvider } from './components/contexts/LocaleContext';
import Router from './Router';

function App() {
  return (
    <LocaleProvider>
      <Router />
    </LocaleProvider>
  );
}

export default App;
