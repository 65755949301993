import styled from 'styled-components';
import { project_subtitle_font } from './constants';

const StyledProjectCategory = styled.div`
  ${({expand}) => expand && 'margin-bottom: 32px;'}

  width: 100%;

  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  section > h2 {
    font-weight: bold;
    font-size: 1.5em;
  }

  section {
    width: 80%;
    max-width: 1180px;
  }

  .subtitle {
    margin-top: 32px;
    font-size: 1.5em;
    font-family: '${project_subtitle_font}', sans-serif;
  }
`;

export default StyledProjectCategory;
