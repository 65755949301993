import styled from 'styled-components';
import { phone_width, responsive_desktop, responsive_table_and_less, tablet_width } from '../../style/constants';

const TopOnTopImages = styled.div`
  ${responsive_desktop} {
    grid-area: image;
    display: grid;
    grid-template-area: "sole";
  }

  ${responsive_table_and_less} {
    margin-top: 3em;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      grid-area: sole;
      margin-bottom: 42px;
    }
  }

  img {
    width: 100%;
    grid-area: sole;
  }

  ${responsive_desktop} {
    .hf-1 {
      position: relative;
      top: -32px;
      right: -32px;
      z-index: 1;
    }

    .hf-2 {
      position: relative;
      top: 32px;
      right: 32px;
      z-index: 0;
    }
  }
`;

export default TopOnTopImages;
