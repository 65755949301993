import { useMemo } from 'react';
import { useLocale } from './components/contexts/LocaleContext';
import en from './lang/en.json';
import fr from './lang/fr.json';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './pages';
import About from './pages/about';
import OnBoarding from './pages/onboarding';
import WhatsNew from './pages/whatsnew';
import FlyUX from './pages/flyux';
import Privacy from './pages/privacy';
import Legal from './pages/legal';
import Confidentiality from './pages/confidentiality';
import Volunteering from './pages/volunteering';

const Router = () => {
  const { locale } = useLocale();

  const messages = useMemo(() => {
    switch (locale) {
      case 'fr':
        return fr;
      case 'en':
        return en;
      default:
        return en;
    }
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      onError={() => null}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/onboarding">
            <OnBoarding />
          </Route>
          <Route exact path="/whatsnew">
            <WhatsNew />
          </Route>
          <Route exact path="/fly">
            <FlyUX />
          </Route>
          <Route exact path="/volunteering">
            <Volunteering />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/legal">
            <Legal />
          </Route>
          <Route exact path="/confidentiality">
            <Confidentiality />
          </Route>
        </Switch>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default Router;
