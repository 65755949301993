import styled from 'styled-components';
import { big_mosaic_image_size, research_image_size, responsive_desktop, responsive_table_and_less } from '../../style/constants';

export const ImagesMosaicBig = ({ children, margin = 40 }) => {
  const Mosaic = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${responsive_desktop} {
    }

    ${responsive_table_and_less} {
      * + * {
        min-margin-left: ${margin}px;
      }
    }

    img {
      width: ${big_mosaic_image_size};
    }

    * + * {
      height: intrinsic;
      min-margin-left: ${margin}px;
    }
  `;
  return (<Mosaic>{children}</Mosaic>)
};


const ImagesMosaic = ({ children, margin = 40 }) => {
  const Mosaic = styled.div`
    display: flex;
    flex-wrap: wrap;

    ${responsive_desktop} {
    }

    ${responsive_table_and_less} {
      img {
        margin-left: ${margin}px;
      }
    }

    img {
      width: ${research_image_size};
      height: intrinsic;
      margin-right: ${margin}px;
      margin-bottom: ${margin}px;
    }
  `;
  return (<Mosaic>{children}</Mosaic>)
};

export default ImagesMosaic;
