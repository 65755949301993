import { useIntl } from 'react-intl';
import Header from '../components/Header';
import H3 from '../components/widgets/h3';
import Page from '../style/Page';
import styled from 'styled-components';

const StyledMain = styled.main`
    display: flex;
    justify-content: center;

    div {
        width: 80%;
    }

    p {
        margin-bottom: 24px;
    }

    h3 {
        margin-top: 48px;
    }
`;

const Legal = () => {
    const intl = useIntl();
    return (
        <Page>
            <Header
                title={intl.formatMessage({
                    id: 'legal.title',
                    defaultMessage: 'Mentions légales',
                    description: 'legal page title',
                })}
                description={[
                ]}
                noColor
            />
            <StyledMain className="columns">
                <div className="column"></div>
                <div className="column is-10">
                    <p>
                        Les présentes conditions générales fixent les modalités d'utilisation du service web https://pakajo.design.
                    </p>
                    <p>
                        L'utilisateur reconnait avoir pris connaissance de ce document et accepté l'ensemble de ces informations, que cet usage soit fait à titre personnel ou professionnel. Si cet usage est fait à titre professionnel, l'utilisateur garantit détenir les pouvoirs nécessaires pour accepter ces conditions générales au sein de son organisation.
                    </p>
                    <H3>
                        Editeur et responsable de publication
                    </H3>
                    <p>
                        Ce site personnel non déclaré à la CNIL est édité par Kathleen Pannetier et développé par Loïc Weissbart.
                    </p>
                    <H3>
                        Hébergement
                    </H3>
                    <p>
                        Ce site est hébergé par OVH.com
                    </p>
                    <p>
                        Siège social : 2 rue Kellermann - 59100 Roubaix - France.
                    </p>
                    <p>
                        Cet hébergeur détient à ce jour les informations personnelles concernant l'auteur de ce site.
                    </p>
                    <H3>
                        Publicité et affiliation
                    </H3>
                    <p>
                        pakajo.design est un site autofinancé, l'hébergement du site est réalisé sur fonds personnels.
                    </p>
                    <H3>
                        Propriété intellectuelle et contrefaçon
                    </H3>
                    <p>
                        Kathleen est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site internet, sauf mentions contraire, notamment les textes, images, graphismes, logos, vidéos, architecture, icônes et sons.
                    </p>
                    <p>
                        Les copies d'écrans de la page du projet d'Onboarding (<a href="/onboarding">https://pakajo.design/onboarding</a>) sont la propriété de Dassault Systèmes.
                    </p>
                    <p>
                        Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de son auteur.
                    </p>
                    <p>
                        Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                    </p>
                    <H3>
                        Limitations de responsabilités
                    </H3>
                    <p>
                        Kathleen ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site http://pakajo.design.
                    </p>
                    <p>
                        Kathleen décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur http://pakajo.design.
                    </p>
                    <p>
                        Kathleen s’engage à sécuriser au mieux le site http://pakajo.design, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.
                    </p>
                    <p>
                        Des espaces interactifs (espace contact) sont à la disposition des utilisateurs. se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.
                    </p>
                    <p>
                        Le cas échéant, se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).
                    </p>
                    <H3>
                        CNIL et gestion des données
                    </H3>
                    <p>
                        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’utilisateur du site http://pakajo.design dispose d’un droit d’accès, de modification et de suppression des informations collectées. Pour exercer ce droit, veuillez utiliser le fomulaire de contact disponible dans la section "A propos": <a href="/about">https://pakajo.design/about</a>.
                    </p>
                    <p>
                        Pour plus d'informations sur la façon dont nous traitons vos données (type de données, finalité, destinataire...), lisez notre politique de confidentialité (<a href="/confidentiality">https://pakajo.design/confidentiality</a>).
                    </p>
                    <H3>
                        Liens hypertextes
                    </H3>
                    <p>
                        Le site http://pakajo.design contient des liens hypertextes vers d’autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers http://pakajo.design.
                    </p>
                    <H3>
                        Droit applicable et attribution de juridiction
                    </H3>
                    <p>
                        Tout litige en relation avec l’utilisation du site http://pakajo.design est soumis au droit français.
                    </p>
                </div>
                <div className="column"></div>
            </StyledMain>
        </Page>
    )
};

export default Legal;
