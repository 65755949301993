import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import Section from '../../layout/Section';
import Button from '../../widgets/Button';
import CategoryTitle from '../../widgets/CategoryTitle';
import ContactForm from './ContactForm';

const Container = styled.div`
  display: flex;
  width: 80%;
  margin-bottom: 32px;
`;

const WellSent = () => (
  <Container>
    <div><FormattedMessage id="form.send.success" /></div>
  </Container>
);

const ErrorWhileSending = () => (
  <Section expanded>
    <div><FormattedMessage id="form.send.error" /></div>
    <ContactForm />
  </Section>
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Contact = () => {
  const [expanded, setExpanded] = useState(false);
  const query = useQuery();
  const status = query.get('status');
  let contact;
  if (status === 'error') {
    contact = (<ErrorWhileSending />);
  } else if (status === 'success') {
    contact = (<WellSent />);
  }

  return (
    <>
      <CategoryTitle>
        <FormattedMessage
          id="contact.title"
        />
      </CategoryTitle>
      {contact || (
        <Section expanded>
          <FormattedMessage
            id="contact.text"
            values={{
              ul: chunks => <ul>{chunks}</ul>,
              li: chunks => <li>{chunks}</li>
            }}
          />
          {expanded ? (
            <ContactForm />
          ) : (
            <Button onClick={() => setExpanded(true)}>
              <FormattedMessage
                id="contact.button"
              />
            </Button>
          )}
        </Section>
      )}
    </>
  );
};

export default Contact;
