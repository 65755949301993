import { FormattedMessage } from 'react-intl';
import StyledProjectCategory from '../../../style/ProjectCategoryStyle';
import TopOnTop from '../../layout/TopOnTop';
import CategoryTitle from '../../widgets/CategoryTitle';
import { DottedLineDown, DottedLineHorizontal } from '../../widgets/DottedLineDown';
import Paragraph from '../../widgets/Paragraph';

const UserTesting = ({ flavor }) => (flavor === 'tablet') ? (
    <div className="my-6 mx-6">
      <StyledProjectCategory className="category">
        <CategoryTitle>
          <FormattedMessage id="testing.category.title" />
        </CategoryTitle>
        <section>
          <Paragraph>
            <FormattedMessage
              id="flyux.testing.intro.1"
              values={{
                strong: chunks => <strong>{chunks}</strong>
              }}
            />
          </Paragraph>
        </section>
      </StyledProjectCategory>
    </div>
  ) : (
  <>
    <TopOnTop>
      <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-2"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
      <div className="foreground columns mt-6 mb-6">
        <div className="column is-2"></div>
        <div className="column is-7 ml-4">
          <StyledProjectCategory className="category">
            <CategoryTitle>
              <FormattedMessage id="testing.category.title" />
            </CategoryTitle>
            <section>
              <Paragraph>
                <FormattedMessage
                  id="flyux.testing.intro.1"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>
                  }}
                />
              </Paragraph>
            </section>
          </StyledProjectCategory>
        </div>
        <div className="column"></div>
      </div>
    </TopOnTop>
    <div className="columns" style={{height: '0px'}}>
      <div className="column is-2"></div>
      <DottedLineHorizontal className="column" />
      <div className="column is-6"></div>
    </div>
    <div className="columns" style={{ height: '0px', marginBottom: '0px', paddingBottom: '2.5em' }}>
      <div className="column is-6" style={{ marginBottom: '0px' }}></div>
      <DottedLineDown className="column" style={{ marginBottom: '0px' }} />
    </div>
  </>
);

export default UserTesting;
