import { FormattedMessage } from "react-intl"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import Paragraph from "../../widgets/Paragraph"
import { DottedBackground } from "./ResearchDetails"

const AnalysisDetails = ({ flavor }) => {
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle>
            <FormattedMessage
              id="analysis.category.title"
            />
          </CategoryTitle>
          <section>
            <Paragraph>
              <FormattedMessage
                id="onboarding.analysis.description.1"
                values={{
                  strong: chunks => <strong>{chunks}</strong>,
                }}
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="onboarding.analysis.description.2"
                values={{
                  strong: chunks => <strong>{chunks}</strong>,
                }}
              />
            </Paragraph>
          </section>
        </div>
      </div>
    )
  }
  return (
    <TopOnTop>
      <DottedBackground size={2} />
      <div className="foreground columns mt-4">
        <div className="column"></div>
        <div className="column is-8 pl-5">
          <CategoryTitle>
            <FormattedMessage
              id="analysis.category.title"
            />
          </CategoryTitle>
          <section className="mb-6">
            <Paragraph>
              <FormattedMessage
                id="onboarding.analysis.description.1"
                values={{
                  strong: chunks => <strong>{chunks}</strong>,
                }}
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="onboarding.analysis.description.2"
                values={{
                  strong: chunks => <strong>{chunks}</strong>,
                }}
              />
            </Paragraph>
          </section>
        </div>
        <div className="column"></div>
      </div>
    </TopOnTop>
  )
}

export default AnalysisDetails
