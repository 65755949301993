import styled from 'styled-components';
import { phone_width, responsive_table_and_less } from '../../style/constants';

const SideText = ({children, invert = false, withMargin = true }) => {
  const Styled = styled.div`
    ${invert && 'grid-area: text;'}

    ${withMargin && `
      ${responsive_table_and_less} {
        margin-bottom: 48px;
      }
    `}
  `;
  return (<Styled className="side-text-container">{children}</Styled>);
};

export default SideText;
