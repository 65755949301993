import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ImageViewer from 'react-simple-image-viewer';

import H3 from '../../widgets/h3';
import CategoryTitle from '../../widgets/CategoryTitle';
import Paragraph from '../../widgets/Paragraph';
import SideText from '../../widgets/SideText';
import SideImage from '../../widgets/SideImage';
import TextAndSideImage from '../../layout/TextAndSideImage';
import ListItem from '../../widgets/ListItem';
import TopOnTop from '../../layout/TopOnTop';
import { DottedLineDown, DottedLineHorizontal } from '../../widgets/DottedLineDown';

const AnalysisDetails = ({ flavor }) => {
  const intl = useIntl();
  const [isAffinityOpen, setAffinityOpen] = useState(false);
  const [isCustomerJourneyOpen, setCustomerJourneyOpen] = useState(false);

  if (flavor === 'tablet') {
    return (
      <>
        <div className="category pt-6 mx-6">
          <CategoryTitle>
            <FormattedMessage id="analysis.category.title" />
          </CategoryTitle>
          <Paragraph>
            <FormattedMessage
              id="flyux.analysis.intro.1"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="flyux.analysis.intro.2"
            />
          </Paragraph>
          <TextAndSideImage className="mt-6">
            <SideText>
              <H3>
                <FormattedMessage
                  id="affinity.diagram.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="flyux.affinity.diagram.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.affinity.diagram.2"
                />
              </Paragraph>
            </SideText>
            <SideImage
              alt={intl.formatMessage({ id: 'alt.flyux.affinity.diagram' })}
              src="./images/flyux/affinity.png"
              onClick={() => setAffinityOpen(true)}
            />
          </TextAndSideImage>
        </div>
        <div className="category mb-6 mx-6">
          <TextAndSideImage className="mt-6" invert>
            <SideText invert>
              <H3>
                <FormattedMessage
                  id="customer.journey.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="flyux.customer.journey.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.customer.journey.2"
                  values={{
                    strong: chunks => <u>{chunks}</u>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>,
                  }}    
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="flyux.customer.journey.3"
                  values={{
                    strong: chunks => <u>{chunks}</u>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>,
                  }}    
                />
              </Paragraph>
            </SideText>
            <SideImage
              invert
              alt={intl.formatMessage({ id: 'alt.flyux.customer.journey.map' })}
              src="./images/flyux/cjm.png"
              onClick={() => setCustomerJourneyOpen(true)}
            />
          </TextAndSideImage>
        </div>
        {isAffinityOpen && (
          <ImageViewer
            src={['./images/flyux/affinity.png']}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside
            onClose={() => {
              setAffinityOpen(false);
            }}
          />
        )}
        {isCustomerJourneyOpen && (
          <ImageViewer
            src={['./images/flyux/cjm.png']}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside
            onClose={() => {
              setCustomerJourneyOpen(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <TopOnTop>
        <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-10"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
        <div className="foreground category columns">
          <div className="column"></div>
          <div className="column is-10 mb-6">
            <CategoryTitle>
              <FormattedMessage id="analysis.category.title" />
            </CategoryTitle>
            <Paragraph>
              <FormattedMessage
                id="flyux.analysis.intro.1"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="flyux.analysis.intro.2"
              />
            </Paragraph>
            <TextAndSideImage className="mt-6">
              <SideText>
                <H3>
                  <FormattedMessage
                    id="affinity.diagram.title"
                  />
                </H3>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.affinity.diagram.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.affinity.diagram.2"
                  />
                </Paragraph>
              </SideText>
              <SideImage
                alt={intl.formatMessage({ id: 'alt.flyux.affinity.diagram' })}
                src="./images/flyux/affinity.png"
                onClick={() => setAffinityOpen(true)}
              />
            </TextAndSideImage>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
      <div className="columns" style={{height: '0px'}}>
        <div className="column is-2"></div>
        <DottedLineHorizontal className="column" />
        <div className="column is-2"></div>
      </div>
      <TopOnTop>
        <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-2"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
        <div className="foreground category columns mb-6">
          <div className="column"></div>
          <div className="column is-10">
            <TextAndSideImage className="mt-6" invert>
              <SideText invert>
                <H3>
                  <FormattedMessage
                    id="customer.journey.title"
                  />
                </H3>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.customer.journey.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.customer.journey.2"
                    values={{
                      strong: chunks => <u>{chunks}</u>,
                      ul: chunks => <ul>{chunks}</ul>,
                      li: chunks => <ListItem>{chunks}</ListItem>,
                    }}    
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="flyux.customer.journey.3"
                    values={{
                      strong: chunks => <u>{chunks}</u>,
                      ul: chunks => <ul>{chunks}</ul>,
                      li: chunks => <ListItem>{chunks}</ListItem>,
                    }}    
                  />
                </Paragraph>
              </SideText>
              <SideImage
                invert
                alt={intl.formatMessage({ id: 'alt.flyux.customer.journey.map' })}
                src="./images/flyux/cjm.png"
                onClick={() => setCustomerJourneyOpen(true)}
              />
            </TextAndSideImage>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
      {isAffinityOpen && (
        <ImageViewer
          src={['./images/flyux/affinity.png']}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside
          onClose={() => {
            setAffinityOpen(false);
          }}
        />
      )}
      {isCustomerJourneyOpen && (
        <ImageViewer
          src={['./images/flyux/cjm.png']}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside
          onClose={() => {
            setCustomerJourneyOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AnalysisDetails;
