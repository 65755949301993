import styled from 'styled-components';
import { primary_color, responsive_table_and_less } from '../../style/constants';

export const dots = `4px dotted ${primary_color}40`;

export const TabletDottedLineDown = styled.div`
    border-left: ${dots};
`;

export const DottedLineDown = styled.div`
    border-left: ${dots};

    ${responsive_table_and_less} {
        display: none;
    }
`;

export const DottedLineHorizontal = styled.div`
    border-top: ${dots};

    ${responsive_table_and_less} {
        display: none;
    }
`;
