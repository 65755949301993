import { body_font, burger_background, burger_button_color, burger_cross_background, burger_hover_color, hover_link } from './constants';
import styled from 'styled-components';
import GoToTop from '../components/widgets/GoToTop';
import CookieBar from '../components/widgets/CookieBar';
import Footer from '../components/widgets/Footer';

export const burger_margin_bottom = 32;
export const link_hover_border_size = 1;
export const link_hover_border_distance = 8;

export const burgerHeight = 30;
export const burgerTop = 16;

const StyledContainer = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: ${burgerHeight}px;
    right: 36px;
    top: ${burgerTop}px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${burger_button_color};
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: ${burger_hover_color};
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${burger_cross_background};
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 0px;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${burger_background};
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: ${burger_background};
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    margin-bottom: 32px;
    text-decoration: none;
    font-weight: bold;
    color: black;
  }

  .bm-item.languages a {
    text-decoration: none;
    color: black;
    margin-right: 8px;
    margin-left: 8px;
  }

  .bm-item:hover {
    margin-bottom: ${burger_margin_bottom - link_hover_border_size - link_hover_border_distance}px;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  a.basic-link:hover {
    padding-bottom: ${link_hover_border_distance}px;
    color: ${hover_link};
  }

  padding: 0;
  margin: 0;
  font-family: '${body_font}', sans-serif;

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }

  a.basic-link {
    text-decoration: none;
  }

  h1 {
    font-weight: bold;
    font-size: 3em;
  }

  header {
    padding-bottom: ${({ littlePadding }) => littlePadding ? '80' : '0'}px;
  }

  main {
    position: relative;
  }

  * {
    box-sizing: border-box;
  }

  .blob {
    position: absolute;
  }

  .star {
    width: 2em;
  }

  .red {
    width: 16em;
  }

  h2 {
    margin: 0px;
  }
`;

const Page = ({ children, colorlessFooter, littlePadding }) => {
  return (
    <StyledContainer id="app" littlePadding={littlePadding}>
      {children}
      <Footer noColor={colorlessFooter} />
      <CookieBar />
      <GoToTop />
    </StyledContainer>
  );
};

export default Page;
