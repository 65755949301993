import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import Header from '../components/Header';
import Overview from '../components/Overview';
import { ProcessOnboarding, types } from '../components/process/Process';
import Project from '../components/Project';
import Takeaways from '../components/Takeaways';
import OverviewModel from '../model/OverviewModel';
import StepModel from '../model/StepModel';
import TakeawayModel, { CUSTOMIZED } from '../model/TakeawayModel';
import { bulma_breakpoint_tablet, responsive_desktop, responsive_table_and_less, tablet_width } from '../style/constants';
import NextPrevious from '../components/widgets/NextPrevious';
import ListItem from '../components/widgets/ListItem';
import ProjectMain from '../components/layout/ProjectMain';
import Page from '../style/Page';
import IntroBar from '../components/widgets/IntroBar';
import { DottedLineDown, DottedLineHorizontal } from '../components/widgets/DottedLineDown';
import ResearchDetails from '../components/pages/onboarding/ResearchDetails';
import AnalysisDetails from '../components/pages/onboarding/AnalysisDetails';
import SketchDetails from '../components/pages/onboarding/SketchDetails';
import PrototypeDetails from '../components/pages/onboarding/PrototypeDetails';
import UserTestingDetails from '../components/pages/onboarding/UserTestingDetails';

export const HorizontalDottedLine = ({ from, to, noRight }) => (
  <div className="columns" style={{height: '0px'}}>
    <div className={`column is-${from ? from : 2}`}></div>
    <DottedLineHorizontal className="column" />
    {noRight !== true && <div className={`column is-${to ? to : 3}`}></div>}
  </div>
);

const ResearchContent = () => [
  (<FormattedMessage
      id="onboarding.process.research.1"
      defaultMessage="<strong>Reused data</strong> from previous user test sessions due to time constraints"
      description="onboarding process: research #1"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
  (<FormattedMessage
      id="onboarding.process.research.2"
      defaultMessage="Ran a <strong>competitive benchmark</strong> to complete the existing findings"
      description="onboarding process: research #2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
];

const Analysis = () => [
  (<FormattedMessage
      id="onboarding.process.analysis.1"
      defaultMessage="Sketched a <strong>high-level user flow</strong> on paper first"
      description="onboarding process: analysis #1"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
  (<FormattedMessage
      id="onboarding.process.analysis.2"
      defaultMessage="Then sketched on Adobe XD to define the structure and navigation of the onboarding"
      description="onboarding process: analysis #2"
    />),
];

const Sketch = () => [
  (<FormattedMessage
      id="onboarding.process.sketch.1"
      defaultMessage="Prepared <strong>detailed paper sketches</strong>"
      description="onboarding process: sketch #1"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
  (<FormattedMessage
      id="onboarding.process.sketch.2"
      defaultMessage="Created a <strong>high- fidelity prototype</strong> that was later on transferred to the UI designer for improvement"
      description="onboarding process: sketch #2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
  (<FormattedMessage
      id="onboarding.process.sketch.3"
      defaultMessage="Provided specifications to the development team"
      description="onboarding process: sketch #3"
    />),
  (<FormattedMessage
      id="onboarding.process.sketch.4"
      defaultMessage="Wrote the <strong>content</strong> of the onboarding"
      description="onboarding process: sketch #4"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
];

const Testing = () => [
  (<FormattedMessage
      id="onboarding.process.testing.1"
      defaultMessage="Recruited participants for the tests"
      description="onboarding process: testing #1"
    />),
  (<FormattedMessage
      id="onboarding.process.testing.2"
      defaultMessage="Conducted <strong>remote tests</strong> with users from different countries (India & France)"
      description="onboarding process: testing #2"
      values={{
        strong: chunks => <strong>{chunks}</strong>
      }}
    />),
];

const columnGap = '32px';

const Diagram = styled.div`
  .diagram-title {
    grid-area: diagram-title;
  }

  ${responsive_table_and_less} {
    margin-right: 3em;
    margin-left: 3em;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows:
      auto
      auto
      auto
      auto
      auto
      auto
      auto
      auto;
    grid-template-areas: 
      "diagram-title diagram-title"
      "step-1 step-1"
      "step-1-image step-1-image"
      "step-1-to-2 step-1-to-2"
      "step-2 step-2"
      "step-2-to-3 step-2-to-3"
      "step-3 step-3"
      "step-3-image step-3-image"
      "step-3-to-4 step-4-to-3"
      "step-4 step-4";
  }

  ${responsive_desktop} {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows:
      auto
      auto
      5em
      auto
      auto
      auto
      auto
      auto;
    grid-template-areas: 
      "diagram-title diagram-title diagram-title diagram-title"
      "step-1 step-1 step-1 ."
      ". step-1-to-2 . ."
      ". step-1-to-2 step-2 step-2"
      ". . step-2-to-3 ."
      "step-3 step-3 step-3 step-3"
      ". step-3-to-4 step-4-to-3 ."
      ". . step-4 step-4";
  }
`;

export const ProjectPart = styled.div`
  display: grid;
  grid-template-rows: auto max-content auto;

  padding-bottom: 0px;

  div.no-margin {
    margin: 0px;
  }
`;

const OnBoardingTablet = () => {
  const intl = useIntl();
  return (
    <Page>
      <Header
        title={intl.formatMessage({
          id: 'onboarding.title',
          defaultMessage: 'Onboarding',
          description: 'onboarding project page title',
        })}
        description={[
          intl.formatMessage({
            id: 'onboarding.description',
          }),
        ]}
        noColor
      />
      <IntroBar
        img="3dstory"
        alt="alt.onboarding.intro.image"
        isTablet
      />
      <ProjectMain>
        <div className="columns mt-6 mr-0">
          <div className="column"></div>
          <div className="column is-6">
            <Overview
              content={[
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.problem.title',
                    defaultMessage: 'The problem',
                    description: 'project problem title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="onboarding.problem.description.1"
                          defaultMessage="3DStory is an app that enables users to quickly pitch their ideas into presentations called ‘stories’. The app is available on desktop and mobile devices."
                          description="onboarding problem description #1"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="onboarding.problem.description.2"
                          defaultMessage="First-time users encountered some issues when using the app."
                          description="onboarding problem description #2"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="onboarding.problem.description.3"
                          defaultMessage="To learn more about the app, see <a>here</a>"
                          description="onboarding problem description #3"
                          values={{
                            a: chunks => (<a className="basic-link" target="_blank" rel="noopener noreferrer" href="https://www.3ds.com/products-services/3dexcite/resource-center/3dstory-pitch-your-projects-in-minutes/">{chunks} <i class="fa-solid fa-arrow-up-right-from-square"></i></a>),
                          }}
                        />
                      </p>
                    </>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'business.requirements.title',
                    defaultMessage: 'Business requirements',
                    description: 'project business requirements title',
                  }),
                  () => (
                    <>
                      <ul>
                        <ListItem>
                          <FormattedMessage
                            id="onboarding.business.requirements.1"
                            defaultMessage="As 3DStory is a new app, it is important to have a <strong>structure</strong> that enable users to <strong>easily learn</strong> and use the product."
                            description="onboarding business requirement #1"
                            values={{
                              strong: chunks => <strong>{chunks}</strong>,
                            }}
                          />
                        </ListItem>
                        <ListItem>
                          <FormattedMessage
                            id="onboarding.business.requirements.2"
                            defaultMessage="The company wants to provide a more <strong>immersive</strong> and <strong>interactive</strong> user help throughout their products."
                            description="onboarding business requirement #2"
                            values={{
                              strong: chunks => <strong>{chunks}</strong>,
                            }}
                          />
                        </ListItem>
                      </ul>
                    </>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.context.title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="onboarding.context.description"
                        />
                      </p>
                    </>
                  )
                ),            
              ]}
            />
          </div>
          <ProjectPart className="column is-4">
            <div className="empty-div-to-center-project-part"></div>
            <Project
              company={intl.formatMessage({
                id: 'dassault.system.company.name',
                defaultMessage: 'Dassault Systèmes',
                description: 'onboarding company name',
              })}
              role={intl.formatMessage({
                id: 'onboarding.role',
                defaultMessage: 'UX Designer & UX Writer',
                description: 'onboarding role name',
              })}
              tools={intl.formatMessage({
                id: 'paper.adobe',
                defaultMessage: 'Paper & Adobe XD',
                description: 'onboarding tools',
              })}
              year={2020}
            />
            <div className="columns no-margin">
              <div className="column"></div>
              <DottedLineDown className="column"></DottedLineDown>
            </div>
          </ProjectPart>
          <div className="column"></div>
        </div>
        <HorizontalDottedLine from={2} to={3} />
        <ProcessOnboarding
          Diagram={Diagram}
          name="onboarding"
          steps={[
            new StepModel(types.research,
              intl.formatMessage({
                id: 'research.category.title',
                defaultMessage: 'Research',
                description: 'process: research title',
              }), ResearchContent()),
            new StepModel(types.analysis,
              intl.formatMessage({
                id: 'analysis.category.title',
                defaultMessage: 'Analysis',
                description: 'process: analysis title',
              }), Analysis()),
            new StepModel(types.sketch,
              intl.formatMessage({
                id: 'sketch.category.title',
                defaultMessage: 'Sketch & Prototype',
                description: 'process: sketch title',
              }), Sketch()),
            new StepModel(types.testing,
              intl.formatMessage({
                id: 'testing.category.title',
                defaultMessage: 'User testing',
                description: 'process: user testing title',
              }), Testing()),
          ]}
          links={[{ alt: 'research.to.analysis', id: '1-to-2' }, { alt: 'analysis.to.sketch', id: '2-to-3' }, { alt: 'sketch.to.usability', id: '3-to-4' }, { alt: 'usability.to.sketch', id: '4-to-3' }]}
        />
        <ResearchDetails flavor="tablet" />
        <AnalysisDetails flavor="tablet" />
        <SketchDetails flavor="tablet" />
        <PrototypeDetails flavor="tablet" />
        <UserTestingDetails flavor="tablet" />
        <Takeaways
          intro={intl.formatMessage({
            id: 'onboarding.takeaways.intro',
            defaultMessage: 'This first professional project as a UX designer was quite challenging.',
            description: 'onboarding takeaways intro',
          })}
          items={[
            new TakeawayModel(
              intl.formatMessage({
                id: 'onboarding.takeaway.1.title',
                defaultMessage: 'Recruiting Users',
                description: 'onboarding takeaway #1 title',
              }), intl.formatMessage({
                id: 'onboarding.takeaway.1.description',
                defaultMessage: 'As external users are not easily accessible, I had to recruit internal participants for the tests. I knew that was not the best solution but it was better than not testing the prototype at all.',
                description: 'onboarding takeaway #1 description',
              }),
            ),
            new TakeawayModel(
              intl.formatMessage({
                id: 'onboarding.takeaway.2.title',
                defaultMessage: 'TESTING REMOTELY',
                description: 'onboarding takeaway #2 title',
              }), intl.formatMessage({
                id: 'onboarding.takeaway.2.description',
                defaultMessage: 'Conducting remote tests was not easy as I could not observe the participants\' reactions.',
                description: 'onboarding takeaway #2 description',
              }),
            ),
            new TakeawayModel(
              intl.formatMessage({
                id: 'onboarding.takeaway.3.title',
                defaultMessage: 'BUSINESS OUTCOMES',
                description: 'onboarding takeaway #3 title',
              }),
              'onboarding.takeaway.3.description',
              CUSTOMIZED,
            ),
          ]}
        />
        <NextPrevious
          next={{
            title: 'flyux.title',
            url: './fly',
          }}
          previous={{
            title: 'volunteering.title.ongoing',
            url: './volunteering',
          }}
        />
      </ProjectMain>
    </Page>
  );
}

const OnBoardingDesktop = () => {
  const intl = useIntl();
  return (
    <Page>
      <Header
        title={intl.formatMessage({
          id: 'onboarding.title',
          defaultMessage: 'Onboarding',
          description: 'onboarding project page title',
        })}
        description={[
          intl.formatMessage({
            id: 'onboarding.description',
          }),
        ]}
        noColor
      />
      <IntroBar
        img="3dstory"
        alt="alt.onboarding.intro.image"
      />
      <ProjectMain>
        <div className="columns mt-6">
          <div className="column"></div>
          <div className="column is-6">
            <Overview
              content={[
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.problem.title',
                    defaultMessage: 'The problem',
                    description: 'project problem title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="onboarding.problem.description.1"
                          defaultMessage="3DStory is an app that enables users to quickly pitch their ideas into presentations called ‘stories’. The app is available on desktop and mobile devices."
                          description="onboarding problem description #1"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="onboarding.problem.description.2"
                          defaultMessage="First-time users encountered some issues when using the app."
                          description="onboarding problem description #2"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="onboarding.problem.description.3"
                          defaultMessage="To learn more about the app, see <a>here</a>"
                          description="onboarding problem description #3"
                          values={{
                            a: chunks => (<a className="basic-link" target="_blank" rel="noopener noreferrer" href="https://www.3ds.com/products-services/3dexcite/resource-center/3dstory-pitch-your-projects-in-minutes/">{chunks} <i class="fa-solid fa-arrow-up-right-from-square"></i></a>),
                          }}
                        />
                      </p>
                    </>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'business.requirements.title',
                    defaultMessage: 'Business requirements',
                    description: 'project business requirements title',
                  }),
                  () => (
                    <>
                      <ul>
                        <ListItem>
                          <FormattedMessage
                            id="onboarding.business.requirements.1"
                            defaultMessage="As 3DStory is a new app, it is important to have a <strong>structure</strong> that enable users to <strong>easily learn</strong> and use the product."
                            description="onboarding business requirement #1"
                            values={{
                              strong: chunks => <strong>{chunks}</strong>,
                            }}
                          />
                        </ListItem>
                        <ListItem>
                          <FormattedMessage
                            id="onboarding.business.requirements.2"
                            defaultMessage="The company wants to provide a more <strong>immersive</strong> and <strong>interactive</strong> user help throughout their products."
                            description="onboarding business requirement #2"
                            values={{
                              strong: chunks => <strong>{chunks}</strong>,
                            }}
                          />
                        </ListItem>
                      </ul>
                    </>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.context.title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="onboarding.context.description"
                        />
                      </p>
                    </>
                  )
                ),            
              ]}
            />
          </div>
          <ProjectPart className="column is-4">
            <div className="empty-div-to-center-project-part"></div>
            <Project
              company={intl.formatMessage({
                id: 'dassault.system.company.name',
                defaultMessage: 'Dassault Systèmes',
                description: 'onboarding company name',
              })}
              role={intl.formatMessage({
                id: 'onboarding.role',
                defaultMessage: 'UX Designer & UX Writer',
                description: 'onboarding role name',
              })}
              tools={intl.formatMessage({
                id: 'paper.adobe',
                defaultMessage: 'Paper & Adobe XD',
                description: 'onboarding tools',
              })}
              year={2020}
            />
            <div className="columns no-margin">
              <div className="column"></div>
              <DottedLineDown className="column"></DottedLineDown>
            </div>
          </ProjectPart>
          <div className="column"></div>
        </div>
        <HorizontalDottedLine from={2} to={3} />
        <ProcessOnboarding
          Diagram={Diagram}
          name="onboarding"
          steps={[
            new StepModel(types.research,
              intl.formatMessage({
                id: 'research.category.title',
                defaultMessage: 'Research',
                description: 'process: research title',
              }), ResearchContent()),
            new StepModel(types.analysis,
              intl.formatMessage({
                id: 'analysis.category.title',
                defaultMessage: 'Analysis',
                description: 'process: analysis title',
              }), Analysis()),
            new StepModel(types.sketch,
              intl.formatMessage({
                id: 'sketch.category.title',
                defaultMessage: 'Sketch & Prototype',
                description: 'process: sketch title',
              }), Sketch()),
            new StepModel(types.testing,
              intl.formatMessage({
                id: 'testing.category.title',
                defaultMessage: 'User testing',
                description: 'process: user testing title',
              }), Testing()),
          ]}
          links={[{ alt: 'research.to.analysis', id: '1-to-2' }, { alt: 'analysis.to.sketch', id: '2-to-3' }, { alt: 'sketch.to.usability', id: '3-to-4' }, { alt: 'usability.to.sketch', id: '4-to-3' }]}
        />
        <HorizontalDottedLine from={2} to={2} />
        <ResearchDetails />
        <HorizontalDottedLine from={2} to={2} />
        <AnalysisDetails />
        <HorizontalDottedLine from={2} to={2} />
        <SketchDetails />
        <HorizontalDottedLine from={2} to={2} />
        <PrototypeDetails />
        <HorizontalDottedLine from={2} to={2} />
        <UserTestingDetails />
        <HorizontalDottedLine from={6} to={2} />
        <div className="columns">
          <div className="column"></div>
          <DottedLineDown className="column" style={{ height: '46px' }}></DottedLineDown>
        </div>
        <Takeaways
          intro={intl.formatMessage({
            id: 'onboarding.takeaways.intro',
            defaultMessage: 'This first professional project as a UX designer was quite challenging.',
            description: 'onboarding takeaways intro',
          })}
          image={{
            alt: '3D Story screenshoot',
            src: './images/3dstory.png',
            props: {
              maxHeight: '80%',
            },
          }}
          items={[
            new TakeawayModel(
              intl.formatMessage({
                id: 'onboarding.takeaway.1.title',
                defaultMessage: 'Recruiting Users',
                description: 'onboarding takeaway #1 title',
              }), intl.formatMessage({
                id: 'onboarding.takeaway.1.description',
                defaultMessage: 'As external users are not easily accessible, I had to recruit internal participants for the tests. I knew that was not the best solution but it was better than not testing the prototype at all.',
                description: 'onboarding takeaway #1 description',
              }),
            ),
            new TakeawayModel(
              intl.formatMessage({
                id: 'onboarding.takeaway.2.title',
                defaultMessage: 'TESTING REMOTELY',
                description: 'onboarding takeaway #2 title',
              }), intl.formatMessage({
                id: 'onboarding.takeaway.2.description',
                defaultMessage: 'Conducting remote tests was not easy as I could not observe the participants\' reactions.',
                description: 'onboarding takeaway #2 description',
              }),
            ),
            new TakeawayModel(
              intl.formatMessage({
                id: 'onboarding.takeaway.3.title',
                defaultMessage: 'BUSINESS OUTCOMES',
                description: 'onboarding takeaway #3 title',
              }),
              'onboarding.takeaway.3.description',
              CUSTOMIZED,
            ),
          ]}
        />
        <NextPrevious
          next={{
            title: 'flyux.title',
            url: './fly',
          }}
          previous={{
            title: 'volunteering.title.ongoing',
            url: './volunteering',
          }}
        />
      </ProjectMain>
    </Page>
  );
};

const OnBoarding = () => {
  return (
    <>
      <MediaQuery minWidth={bulma_breakpoint_tablet}>
        <OnBoardingDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
        <OnBoardingTablet />
      </MediaQuery>
    </>
  )
}

export default OnBoarding;
