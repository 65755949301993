import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { primary_color, process_step_icon_size } from '../../style/constants';
import ListItem from '../widgets/ListItem';

const StyledContainer = styled.div`
  display: grid;
  grid-template-areas:
    "title"
    "list";
  grid-template-rows: max-content auto;
  padding: 32px 32px 32px 32px;
  border: 4px solid ${primary_color};
  border-radius: 4px;
  margin-bottom: 48px;
  min-width: 22em;

  h3 {
    grid-area: title;
    font-weight: bold;
    margin-bottom: 8px;
  }

  ul {
    grid-area: list;
  }
`;

const Skill = ({ title, content }) => (
  <StyledContainer>
    <h3>
      <FormattedMessage
        id={title} />
    </h3>
    <ul>
      {content.map((text) => (<ListItem>{text}</ListItem>))}
    </ul>
  </StyledContainer>
);

export default Skill;
