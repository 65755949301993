import { useIntl } from 'react-intl';
import Header from '../components/Header';
import Page from '../style/Page';
import styled from 'styled-components';

const StyledMain = styled.main`
    display: flex;
    justify-content: center;

    p {
        margin-bottom: 24px;
    }

    h3 {
        margin-top: 48px;
    }
`;

const Confidentiality = () => {
    const intl = useIntl();
    return (
        <Page>
            <Header
                title={intl.formatMessage({
                    id: 'confidentiality.title',
                    defaultMessage: 'Politique de confidentialité',
                    description: 'confidentiality page title',
                })}
                description={[
                ]}
                noColor
            />
            <StyledMain className="columns">
                <div className="column"></div>
                <div className="column is-10">
                    <p>
                        Votre vie privée est importante. Les seules données personnelles recueillies sur le site le sont via le formulaire de contact, dans la section 'A propos', et sont traitées en conformité avec les lois et règlements applicables en France ainsi qu'avec le Règlement Général Européen sur la protection des données. Cette politique de confidentialité explique la nature, l'étendue et l'objectif de la collecte et du traitement des données personnelles.
                    </p>
                    <p>
                        Les informations reccueillies via le formulaire de contact sont utilisées afin d'envoyer un courrier électronique. En soumettant le formulaire, vous consentez à ce que les informations fournies soient utilisées pour envoyer un courrier électronique à Kathleen Pannetier, seule destinataire des données collectées.
                    </p>
                    <p>
                        Vos données traitées sont les données d'identification: nom et email. Elles sont recueillies directement auprès de vous via le formulaire de contact du site pakajo.design.
                    </p>
                    <p>
                        Vos données personnelles sont conservées pour la durée nécessaire au traitement de votre demande d'information. Elles sont conservées durant 12 mois puis supprimées automatiquement.
                    </p>
                    <p>
                        Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données.
                    </p>
                    <p>
                        Consultez le site cnil.fr pour plus d'informations sur vos droits.
                    </p>
                    <p>
                        Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez utiliser le contact de la section "A propos" (<a href="/about">http://pakajo.design/about</a>).
                    </p>
                    <p>
                        Si vous estimez, après nous avoir contactés, que vos droits "Informatique et Libertés" ne sont pas respectés, vous pouvez addresser une réclamation à la CNIL.
                    </p>
                </div>
                <div className="column"></div>
            </StyledMain>
        </Page>
    )
};

export default Confidentiality;
