import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import Header from '../components/Header';
import Overview from '../components/Overview';
import Project from '../components/Project';
import Takeaways from '../components/Takeaways';
import OverviewModel from '../model/OverviewModel';
import TakeawayModel from '../model/TakeawayModel';
import { bulma_breakpoint_tablet, responsive_desktop, responsive_table_and_less } from '../style/constants';
import NextPrevious from '../components/widgets/NextPrevious';
import ListItem from '../components/widgets/ListItem';
import ProjectMain from '../components/layout/ProjectMain';
import Page from '../style/Page';
import IntroBar from '../components/widgets/IntroBar';
import { DottedLineDown, DottedLineHorizontal } from '../components/widgets/DottedLineDown';
import WireframesDetails from '../components/pages/whatsnew/Wireframes';
import PrototypeDetails from '../components/pages/whatsnew/Prototype';
import { HorizontalDottedLine, ProjectPart } from './onboarding';

const WhatsNewTablet = () => {
  const intl = useIntl();
  return (
    <Page>
      <Header
        title={intl.formatMessage({
          id: 'whatsnew.title',
        })}
        description={[
          intl.formatMessage({
            id: 'whatsnew.description',
          }),
        ]}
        noColor
      />
      <IntroBar
        img="whatsnew"
        alt="alt.whatsnew.intro.image"
        isTablet
      />
      <ProjectMain>
        <div className="columns mt-6 mr-0">
          <div className="column"></div>
          <div className="column is-6">
            <Overview
              content={[
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.problem.title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="whatsnew.problem.description.1"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="whatsnew.problem.description.2"
                          values={{
                            strong: chunks => (<strong>{chunks}</strong>),
                          }}
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="whatsnew.problem.description.3"
                          values={{
                            strong: chunks => (<strong>{chunks}</strong>),
                          }}
                        />
                      </p>
                    </>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'business.requirements.title',
                  }),
                  () => (
                    <p>
                      <FormattedMessage
                        id="whatsnew.business.requirements.1"
                        values={{
                          strong: chunks => <strong>{chunks}</strong>,
                        }}
                      />
                    </p>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.context.title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="whatsnew.context.description"
                        />
                      </p>
                    </>
                  )
                ),            
              ]}
            />
          </div>
          <ProjectPart className="column is-4">
            <div className="empty-div-to-center-project-part"></div>
            <Project
              company={intl.formatMessage({
                id: 'dassault.system.company.name',
              })}
              role={intl.formatMessage({
                id: 'whatsnew.role',
              })}
              tools={intl.formatMessage({
                id: 'paper.adobe',
              })}
              year={2021}
            />
            <div className="columns no-margin">
              <div className="column"></div>
              <DottedLineDown className="column"></DottedLineDown>
            </div>
          </ProjectPart>
          <div className="column"></div>
        </div>
        <HorizontalDottedLine from={2} to={3} />
        <WireframesDetails flavor="tablet" />
        <PrototypeDetails flavor="tablet" />
        <Takeaways
          title="business.outcome.title"
          items={[
            new TakeawayModel(
              undefined, intl.formatMessage({
                id: 'whatsnew.takeaway.description.1',
              }),
            ),
            new TakeawayModel(
              undefined, intl.formatMessage({
                id: 'whatsnew.takeaway.description.2',
              }),
            ),
            new TakeawayModel(
              undefined, intl.formatMessage({
                id: 'whatsnew.takeaway.description.3',
              }),
            ),
          ]}
        />
        <NextPrevious
          previous={{
            title: 'flyux.title',
            url: './fly',
          }}
        />
      </ProjectMain>
    </Page>
  );
}

const WhatsNewDesktop = () => {
  const intl = useIntl();
  return (
    <Page>
      <Header
        title={intl.formatMessage({
          id: 'whatsnew.title',
        })}
        description={[
          intl.formatMessage({
            id: 'whatsnew.description',
          }),
        ]}
        noColor
      />
      <IntroBar
        img="whatsnew"
        alt="alt.whatsnew.intro.image"
      />
      <ProjectMain>
        <div className="columns mt-6">
          <div className="column"></div>
          <div className="column is-6">
            <Overview
              content={[
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.problem.title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="whatsnew.problem.description.1"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="whatsnew.problem.description.2"
                          values={{
                            strong: chunks => <strong>{chunks}</strong>,
                          }}
                          />
                      </p>
                      <p>
                        <FormattedMessage
                          id="whatsnew.problem.description.3"
                          values={{
                            strong: chunks => <strong>{chunks}</strong>,
                          }}
                          />
                      </p>
                    </>
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'business.requirements.title',
                  }),
                  () => (
                    <FormattedMessage
                      id="whatsnew.business.requirements.1"
                      values={{
                        strong: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  )
                ),
                new OverviewModel(
                  intl.formatMessage({
                    id: 'project.context.title',
                  }),
                  () => (
                    <>
                      <p>
                        <FormattedMessage
                          id="whatsnew.context.description"
                        />
                      </p>
                    </>
                  )
                ),            
              ]}
            />
          </div>
          <ProjectPart className="column is-4">
            <div className="empty-div-to-center-project-part"></div>
            <Project
              company={intl.formatMessage({
                id: 'dassault.system.company.name',
              })}
              role={intl.formatMessage({
                id: 'whatsnew.role',
              })}
              tools={intl.formatMessage({
                id: 'paper.adobe',
              })}
              year={2021}
            />
            <div className="columns no-margin">
              <div className="column"></div>
              <DottedLineDown className="column"></DottedLineDown>
            </div>
          </ProjectPart>
          <div className="column"></div>
        </div>
        <HorizontalDottedLine from={2} to={3} />
        <WireframesDetails />
        <HorizontalDottedLine from={2} to={2} />
        <PrototypeDetails />
        <HorizontalDottedLine from={6} to={2} />
        <div className="columns">
          <div className="column"></div>
          <DottedLineDown className="column" style={{ height: '46px' }}></DottedLineDown>
        </div>
        <Takeaways
          title="business.outcome.title"
          items={[
            new TakeawayModel(
              undefined, intl.formatMessage({
                id: 'whatsnew.takeaway.description.1',
              }),
            ),
            new TakeawayModel(
              undefined, intl.formatMessage({
                id: 'whatsnew.takeaway.description.2',
              }),
            ),
            new TakeawayModel(
              undefined, intl.formatMessage({
                id: 'whatsnew.takeaway.description.3',
              }),
            ),
          ]}
        />
        <NextPrevious
          previous={{
            title: 'flyux.title',
            url: './fly',
          }}
        />
      </ProjectMain>
    </Page>
  );
};

const WhatsNew = () => {
  return (
    <>
      <MediaQuery minWidth={bulma_breakpoint_tablet}>
        <WhatsNewDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
        <WhatsNewTablet />
      </MediaQuery>
    </>
  )
}

export default WhatsNew;
