import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Tag from './Tag';
import { header_background_color } from '../../style/constants';

const CardLinks = styled.a`
    z-index: 2;
    top: 0px;
    transition: top 0.5s ease-in-out, box-shadow 0.5s ease-in-out;

    :hover {
        top: -8px;
        box-shadow: 0 1em 1em 0.125em rgba(10, 10, 10, 0.3);
    }
`;

const Deactivated = styled.div`
    transition: filter 0.2s ease-in-out;

    :hover {
        filter: grayscale(100%);
    }

    .coming-soon {
        font-weight: normal;
        font-style: italic;
    }
`;

const Card = ({
    title,
    topics,
    image,
    link,
    big,
    color,
}) => {
    const intl = useIntl();
    return (
        link ? (
            <CardLinks className={`card tile is-child`} href={link}>
                <div className="card-image px-6 py-6" style={{ backgroundColor: color }}>
                    <figure className="image is-16by9">
                        <img
                            src={`./images/${image}`}
                            alt={intl.formatMessage({ id: `alt.index.${image}.card` })}
                        />
                    </figure>
                </div>
                <div className="card-content">
                    <div>
                        {topics.map((topic) => (
                            <Tag>{topic}</Tag>
                        ))}
                    </div>
                    <p className="title"><FormattedMessage id={title} /></p>
                </div>
            </CardLinks>
        ) : (
            <Deactivated className={`card tile is-child`}>
                <div className="card-image">
                    <figure className="image is-3by2">
                        <img
                            src={`./images/${image}_low.jpeg`}
                            alt={intl.formatMessage({ id: `alt.index.${image}.card` })}
                        />
                    </figure>
                </div>
                <div className="card-content">
                    <div>
                        {topics.map((topic) => (
                            <Tag>{topic}</Tag>
                        ))}
                    </div>
                    <p className="title"><FormattedMessage id={title} /><span className="coming-soon"><FormattedMessage id="coming.soon" /></span></p>
                </div>
            </Deactivated>
        )
    )
};

export default Card;
