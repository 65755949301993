import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { presentation_name_color, project_category_font } from '../../style/constants';

const StyledH2 = styled.h2`
  margin-bottom: 32px;
  font-family: '${project_category_font}', sans-serif;
  font-weight: bold;
  font-size: 24px;

  strong {
    color: ${presentation_name_color};
  }
`;

const Presentation = () => (
  <>
    <StyledH2 className="mb-6">
      <FormattedMessage
        id="presentation.start.text"
        defaultMessage="Hello! <strong>I am Kathleen!</strong>"
        description="presentation start text"
        values={{
          strong: chunks => <strong>{chunks}</strong>
        }}
      />
    </StyledH2>
    <p>
      <FormattedMessage
        id="presentation.paragraph.1"
        defaultMessage="I am a French UX designer based in Paris with 10 years of experience in technical writing."
        description="presentation paragraph #1"
      />
    </p>
    <p>
      <FormattedMessage
        id="presentation.paragraph.2"
        defaultMessage="As I progressed in my career, I became aware that considering users' needs is essential when writing content. This is how I got interested in UX design."
        description="presentation paragraph #2"
      />
    </p>
    <p>
      <FormattedMessage
        id="presentation.paragraph.3"
        defaultMessage="In 2019, I decided to enroll in the UX Design Institute program to deep dive into UX design, and to make the content I produced more user-oriented."
        description="presentation paragraph #3"
      />
    </p>
    <p>
      <FormattedMessage
        id="presentation.paragraph.4"
        defaultMessage="On my free time, I like drawing, dancing and hiking."
        description="presentation paragraph #4"
      />
    </p>
    <p>
      <FormattedMessage
        id="presentation.paragraph.5"
        values={{
          a: chunks => <a className="basic-link" href="https://www.linkedin.com/in/kathleen-p-93312316/">{chunks} <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
        }}
      />
    </p>
  </>
);

export default Presentation;
