import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import ImageViewer from 'react-simple-image-viewer';

import { responsive_desktop, responsive_table_and_less, tablet_width } from '../../../style/constants';
import H3 from '../../widgets/h3';
import CategoryTitle from '../../widgets/CategoryTitle';
import ListItem from '../../widgets/ListItem';
import Paragraph from '../../widgets/Paragraph';
import ImagesMosaic from '../../widgets/ImagesMosaic';
import SideText from '../../widgets/SideText';
import SideImage from '../../widgets/SideImage';
import Image from '../../widgets/Image';
import TopOnTop from '../../layout/TopOnTop';
import { DottedLineDown, DottedLineHorizontal } from '../../widgets/DottedLineDown';

const Survey = styled.div`
  ${responsive_desktop} {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 56px;
    margin-bottom: 3em;
  }

  ${responsive_table_and_less} {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
  }
`;

const Usability = styled.div`
  ${responsive_desktop} {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "image text";
    grid-gap: 56px;
    margin-top: 64px;
  }

  ${responsive_table_and_less} {
    display: flex;
    flex-direction: column;
  }
`;

const CompetitiveBenchmark = styled.div`
  margin-top: 64px;
`;

const Research = ({ flavor }) => {
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isUsabilityOpen, setUsabilityOpen] = useState(false);
  const images = [
    './images/flyux/survey_1.png',
    './images/flyux/survey_2.png',
    './images/flyux/survey_3.png',
    './images/flyux/survey_4.png',
    './images/flyux/survey_5.png',
    './images/flyux/survey_6.png',
  ];
  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (flavor === 'tablet') {
    return (
      <>
        <div className="columns mt-4 mr-0">
          <div className="column"></div>
          <div className="column is-10 mx-6">
            <CategoryTitle>
              <FormattedMessage
                id="research.category.title"
                defaultMessage="Research"
                description="research category title"
              />
            </CategoryTitle>
            <Paragraph>
              <FormattedMessage
                id="research.description.1"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="research.description.2"
              />
            </Paragraph>
            <Survey>
              <SideText>
                <H3>
                  <FormattedMessage
                    id="survey.phase.title"
                  />
                </H3>
                <Paragraph>
                  <FormattedMessage
                    id="survey.phase.description.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="survey.phase.description.2"
                    values={{
                      ul: chunks => <ul>{chunks}</ul>,
                      li: chunks => <ListItem>{chunks}</ListItem>,
                    }}    
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="survey.result"
                  />
                </Paragraph>
              </SideText>
              <ImagesMosaic margin={20}>
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.1' })}
                  src="./images/flyux/survey_1.png"
                  onClick={() => openImageViewer(0)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.2' })}
                  src="./images/flyux/survey_2.png"
                  onClick={() => openImageViewer(1)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.3' })}
                  src="./images/flyux/survey_3.png"
                  onClick={() => openImageViewer(2)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.4' })}
                  src="./images/flyux/survey_4.png"
                  onClick={() => openImageViewer(3)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.5' })}
                  src="./images/flyux/survey_5.png"
                  onClick={() => openImageViewer(4)}
                />
                <Image
                  alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.6' })}
                  src="./images/flyux/survey_6.png"
                  onClick={() => openImageViewer(5)}
                />
              </ImagesMosaic>
            </Survey>
          </div>
        </div>
        <div className="columns mr-0">
          <div className="column is-10 mx-6">
            <Usability>
              <SideText invert>
                <H3>
                  <FormattedMessage
                    id="usability.testing.title"
                  />
                </H3>
                <Paragraph>
                  <FormattedMessage
                    id="usability.testing.description.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="usability.testing.description.2"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="usability.testing.description.3"
                    values={{
                      ul: chunks => <ul>{chunks}</ul>,
                      li: chunks => <ListItem>{chunks}</ListItem>,
                    }}    
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="usability.testing.description.4"
                  />
                </Paragraph>
              </SideText>
              <SideImage
                invert
                alt={intl.formatMessage({ id: 'alt.flyux.usability.test.screnshot' })}
                src="./images/usability_test_1.png"
                onClick={() => {
                  setUsabilityOpen(true);
                }}
              />
            </Usability>
          </div>
        </div>
        <div className="mt-4 mb-6 mx-6 mr-0">
            <H3>
              <FormattedMessage
                id="interview.title"
              />
            </H3>
            <Paragraph>
              <FormattedMessage
                id="interview.description.1"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="interview.description.2"
              />
            </Paragraph>
            <CompetitiveBenchmark>
              <H3>
                <FormattedMessage
                  id="competitive.benchmark.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="competitive.benchmark.description.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="competitive.benchmark.description.2"
                />
              </Paragraph>
            </CompetitiveBenchmark>
          </div>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside
            onClose={closeImageViewer}
          />
        )}

        {isUsabilityOpen && (
          <ImageViewer
            src={['./images/usability_test_1.png']}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside
            onClose={() => {
              setCurrentImage(0);
              setUsabilityOpen(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      <TopOnTop>
        <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-9"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
        <div className="foreground columns mt-4">
          <div className="column"></div>
          <div className="column is-10">
            <CategoryTitle>
              <FormattedMessage
                id="research.category.title"
                defaultMessage="Research"
                description="research category title"
              />
            </CategoryTitle>
            <section>
              <div className="column is-9" style={{ margin: '0px', padding: '0px' }}>
                <Paragraph>
                  <FormattedMessage
                    id="research.description.1"
                  />
                </Paragraph>
              </div>
              <div className="column is-9" style={{ margin: '0px', padding: '0px' }}>
                <Paragraph>
                  <FormattedMessage
                    id="research.description.2"
                  />
                </Paragraph>
              </div>
              <Survey>
                <SideText>
                  <H3>
                    <FormattedMessage
                      id="survey.phase.title"
                    />
                  </H3>
                  <Paragraph>
                    <FormattedMessage
                      id="survey.phase.description.1"
                    />
                  </Paragraph>
                  <Paragraph>
                    <FormattedMessage
                      id="survey.phase.description.2"
                      values={{
                        ul: chunks => <ul>{chunks}</ul>,
                        li: chunks => <ListItem>{chunks}</ListItem>,
                      }}    
                    />
                  </Paragraph>
                  <Paragraph>
                    <FormattedMessage
                      id="survey.result"
                    />
                  </Paragraph>
                </SideText>
                <ImagesMosaic>
                  <Image
                    alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.1' })}
                    src="./images/flyux/survey_1.png"
                    onClick={() => openImageViewer(0)}
                  />
                  <Image
                    alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.2' })}
                    src="./images/flyux/survey_2.png"
                    onClick={() => openImageViewer(1)}
                  />
                  <Image
                    alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.3' })}
                    src="./images/flyux/survey_3.png"
                    onClick={() => openImageViewer(2)}
                  />
                  <Image
                    alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.4' })}
                    src="./images/flyux/survey_4.png"
                    onClick={() => openImageViewer(3)}
                  />
                  <Image
                    alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.5' })}
                    src="./images/flyux/survey_5.png"
                    onClick={() => openImageViewer(4)}
                  />
                  <Image
                    alt={intl.formatMessage({ id: 'alt.flyux.research.survey.screenshot.6' })}
                    src="./images/flyux/survey_6.png"
                    onClick={() => openImageViewer(5)}
                  />
                </ImagesMosaic>
              </Survey>
            </section>
          <div className="column"></div>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
      <div className="columns" style={{height: '0px'}}>
        <div className="column is-2"></div>
        <DottedLineHorizontal className="column" />
        <div className="column is-3"></div>
      </div>
      <TopOnTop>
        <div className="background">
          <div className="columns" style={{ height: '100%' }}>
            <div className="column is-2"></div>
            <DottedLineDown className="column" />
          </div>
        </div>
        <div className="foreground category mt-4 mb-6">
          <div className="columns">
            <div className="column"></div>
            <div className="column is-10">
              <Usability>
                <SideText invert>
                  <H3>
                    <FormattedMessage
                      id="usability.testing.title"
                    />
                  </H3>
                  <Paragraph>
                    <FormattedMessage
                      id="usability.testing.description.1"
                    />
                  </Paragraph>
                  <Paragraph>
                    <FormattedMessage
                      id="usability.testing.description.2"
                    />
                  </Paragraph>
                  <Paragraph>
                    <FormattedMessage
                      id="usability.testing.description.3"
                      values={{
                        ul: chunks => <ul>{chunks}</ul>,
                        li: chunks => <ListItem>{chunks}</ListItem>,
                      }}    
                    />
                  </Paragraph>
                  <Paragraph>
                    <FormattedMessage
                      id="usability.testing.description.4"
                    />
                  </Paragraph>
                </SideText>
                <SideImage
                  invert
                  alt={intl.formatMessage({ id: 'alt.flyux.usability.test.screnshot' })}
                  src="./images/usability_test_1.png"
                  onClick={() => {
                    setUsabilityOpen(true);
                  }}
                />
              </Usability>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </TopOnTop>
      <div className="columns" style={{height: '0px'}}>
        <div className="column is-2"></div>
        <DottedLineHorizontal className="column" />
        <div className="column is-2"></div>
      </div>
      <TopOnTop>
        <div className="columns background">
          <div className="column is-10"></div>
          <DottedLineDown className="column" />
        </div>
        <div className="columns foreground mb-6">
          <div className="column is-1"></div>
          <div className="column is-9">
            <div className="mt-4">
              <H3>
                <FormattedMessage
                  id="interview.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="interview.description.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="interview.description.2"
                />
              </Paragraph>
            </div>
            <CompetitiveBenchmark>
              <H3>
                <FormattedMessage
                  id="competitive.benchmark.title"
                />
              </H3>
              <Paragraph>
                <FormattedMessage
                  id="competitive.benchmark.description.1"
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="competitive.benchmark.description.2"
                />
              </Paragraph>
            </CompetitiveBenchmark>
          </div>
          <div className="column"></div>
        </div>
      </TopOnTop>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}

      {isUsabilityOpen && (
        <ImageViewer
          src={['./images/usability_test_1.png']}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside
          onClose={() => {
            setCurrentImage(0);
            setUsabilityOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Research;
