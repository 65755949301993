import { useIntl } from 'react-intl';
import Presentation from '../components/about/Presentation';
import Skills from '../components/about/Skills';
import Header from '../components/Header';
import styled from 'styled-components';
import Contact from '../components/pages/about/Contact';
import Page from '../style/Page';
import { AvatarBar } from '../components/widgets/IntroBar';
import { responsive_table_and_less } from '../style/constants';

const StyledMain = styled.main`
  position: relative;

  ${responsive_table_and_less} {
    margin-left: 3em;
    margin-right: 3em;
  }

  p {
    margin-bottom: 1em;
  }

`;

const About = () => {
  const intl = useIntl();
  return (
    <Page>
      <Header
        title={intl.formatMessage({
          id: 'about.title',
          defaultMessage: 'A little about me',
          description: 'about page title',
        })}
        description={[
          intl.formatMessage({
            id: 'about.description.1',
          }),
        ]}
        noColor
      />
      <AvatarBar />
      <StyledMain>
        <div className="columns mt-6">
          <div className="column"></div>
          <div className="column is-10">
            <Presentation />
            <Skills />
            <Contact />
          </div>
          <div className="column"></div>
        </div>
      </StyledMain>
    </Page>
  );
};

export default About;
