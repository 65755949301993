import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { CUSTOMIZED } from '../model/TakeawayModel';
import { bulma_breakpoint_tablet, phone_width, primary_color, responsive_phone, responsive_table_and_less, secondary_color, tablet_width } from '../style/constants';
import CategoryTitle from './widgets/CategoryTitle';
import H3 from './widgets/h3';

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;

  blockquote {
    line-height: 1.4;
    position: relative;
    font-style: italic;
    margin-top: 32px;
    margin-bottom: 32px;
    align-self: center;
  }
  
  blockquote:before,
  blockquote:after {
    position: absolute;
    color: ${primary_color};
    font-size: 6rem;
    width: 4rem;
    height: 4rem;
  }
  
  @media screen and (min-width: ${tablet_width}px) {
    blockquote {
      max-width: 400px;
      width: 60%;
    }

    blockquote:before {
      content: '“';
      left: -5rem;
      top: -2rem;
    }
    
    blockquote:after {
      content: '”';
      right: -3rem;
      bottom: 0rem;
    }
  }

  @media screen and (max-width: ${phone_width}px) {
      blockquote {
        max-width: 70%;
      }

      blockquote:before {
        content: '“';
        left: -3rem;
        top: -2rem;
      }
      
      blockquote:after {
        content: '”';
        right: -3rem;
        bottom: 0rem;
      }  
  }
`;

const RenderTakeaway = ({ model }) => (
  <SubContainer>
    {model.title && <H3 noMargin>{model.title}</H3>}
    {model.type === CUSTOMIZED
      ? (
        <FormattedMessage
          id={model.description}
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            blockquote: (...chunks) => <blockquote>{chunks}</blockquote>,
        }}
        />
      ) : (
        <p>{model.description}</p>
      )
    }
  </SubContainer>
);

const StyledContainer = styled.div`
    display: grid;
    grid-template-areas: "main-space";

    .background {
        grid-area: main-space;
        background-color: ${secondary_color}20;
        height: 100%;
        align-self: end;
        position: relative;
        top: 64px;
    }

    .foreground {
        grid-area: main-space;
        z-index: 1;
    }

    .intro-image {
        margin-bottom: 32px;
    }

`;

const Container = styled.div`
  .takeaways {
    border: 1px solid ${secondary_color};
    background-color: white;

    ${responsive_table_and_less} {
      margin-left: 6em;
      margin-right: 6em;
    }

    ${responsive_phone} {
      margin-left: 3em;
      margin-right: 3em;
    }
  }

  margin-bottom: 6em;
`;


const Takeaways = ({ intro, items, title = "takeaways.title" }) => (
  <Container>
    <MediaQuery minWidth={bulma_breakpoint_tablet}>
      <StyledContainer>
        <div className="background"></div>
        <div className="foreground columns">
          <div className="column"></div>
          <div className="column takeaways is-6 px-6 py-6">
            <CategoryTitle noMargin>
              <FormattedMessage
                id={title}
              />
            </CategoryTitle>
            <section>
              {intro && <SubContainer>{intro}</SubContainer>}
              {items.map((item) => (
                <RenderTakeaway model={item} />
              ))}
            </section>
          </div>
          <div className="column"></div>
        </div>
      </StyledContainer>
    </MediaQuery>
    <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
      <StyledContainer className="mt-6">
          <div className="background"></div>
          <div className="foreground takeaways px-6 py-6">
            <CategoryTitle noMargin>
              <FormattedMessage
                id="takeaways.title"
                defaultMessage="Takeaways"
                description="takeaways title"
              />
            </CategoryTitle>
            <section>
              {intro && <SubContainer>{intro}</SubContainer>}
              {items.map((item) => (
                <RenderTakeaway model={item} />
              ))}
            </section>
          </div>
      </StyledContainer>
    </MediaQuery>
  </Container>
);

export default Takeaways;
