import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { phone_width } from '../../style/constants';
import Image from './Image';

const Styled = styled.div`
  ${({ invert }) => invert && 'grid-area: image;'}

  img {
    width: 100%;
  }

  @media screen and (max-width: ${phone_width}px) {
    margin-bottom: 48px;
  }

  ${({ caption }) => caption && `
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.5em;

    span {
      margin-top: 8px;
    }
  `}
`;

const SideImage = (props) => (
  <Styled className="side-image-container"  invert={props.invert} caption={props.caption !== undefined}>
    <Image
      {...props}
    />
    {props.caption ? (<span><FormattedMessage id={props.caption} /></span>) : null}
  </Styled>
)

export default SideImage;
