export default class StepModel {
  constructor(
    type,
    title,
    content
  ) {
    this.type = type;
    this.title = title;
    this.content = content;
  }
}
