import styled from 'styled-components';
import { responsive_desktop } from '../style/constants';
import Cartouche from './widgets/Cartouche';

const StyledContainer = styled.div`
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  animation: fadein 1s;

  width: 80%;
  justify-self: center;
`;

const Project = ({ company, role, tools, year }) => (
  <StyledContainer>
    <Cartouche
      company={company}
      role={role}
      tools={tools}
      year={year}
    />
  </StyledContainer>
);

export default Project;
