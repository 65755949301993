import styled from 'styled-components';

const Image = styled.img`
  transition :all .2s ease-in-out;
  border: 1px solid hsl(0deg, 0%, 29%);

  ${({ maxHeight }) => maxHeight && `
    display: block;
    max-height: ${maxHeight};
    width: auto !important;
    max-width: 100%;
  `}

  ${({notNavigable}) => !notNavigable && `:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 0.5em 0.5em 0.125em rgba(10, 10, 10, 0.1);
  }`}
`;

export default Image;
