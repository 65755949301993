import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { bulma_breakpoint_tablet, secondary_color, tablet_width } from '../../style/constants';
import Star from './Star';
import Me from '../about/Me';
import { useIntl } from 'react-intl';

const StyledContainer = styled.div`
    display: grid;
    grid-template-areas: "main-space";

    .background {
        grid-area: main-space;
        background-color: ${secondary_color}20;
        height: 90%;
        align-self: end;
    }

    .foreground {
        grid-area: main-space;
    }

    .intro-image {
        margin-bottom: 32px;
    }
`;

const IntroBar = ({ img, isTablet, alt }) => {
    const intl = useIntl();
    return (
        <StyledContainer className="mt-4">
            <div className="background"></div>
            <div className={`foreground columns ${isTablet ? 'mr-0' : ''}`}>
                <MediaQuery minWidth={bulma_breakpoint_tablet}>
                    <div className="column is-1"></div>
                    <div className="column">
                        <div style={{ height: '100%'}}  className="mr-6 is-flex is-align-items-center justify-flex-end">
                            <Star />
                        </div>
                    </div>
                    <div className="column is-flex is-justify-content-center">
                        <img
                            className="intro-image"
                            src={`./images/${img}.png`}
                            alt={intl.formatMessage({ id: alt })}
                        />
                    </div>
                    <div className="column">
                        <div style={{ height: '100%'}}  className="ml-6 is-flex is-align-items-center">
                            <Star color="green" />
                        </div>
                    </div>
                    <div className="column is-1"></div>
                </MediaQuery>
                <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
                    <div className="mx-6 px-6 is-flex is-justify-content-center">
                        <img
                            className="intro-image"
                            src={`./images/${img}.png`}
                            alt={intl.formatMessage({ id: alt })}
                        />
                    </div>
                </MediaQuery>
            </div>
        </StyledContainer>
    );
}

export const AvatarBar = () => (
    <StyledContainer className="mt-4">
        <div className="background" style={{ height: '70%' }}></div>
        <div className="foreground columns">
            <MediaQuery minWidth={bulma_breakpoint_tablet}>
                <div className="column is-1"></div>
                <div className="column">
                    <div style={{ height: '100%'}}  className="mr-6 is-flex is-align-items-center justify-flex-end">
                        <Star />
                    </div>
                </div>
                <div className="column"><Me /></div>
                <div className="column">
                    <div style={{ height: '100%'}}  className="ml-6 is-flex is-align-items-center">
                        <Star color="green" />
                    </div>
                </div>
                <div className="column is-1"></div>
            </MediaQuery>
            <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
                <div className="mx-6 px-6"><Me /></div>
            </MediaQuery>
        </div>
    </StyledContainer>
);

export default IntroBar;
