import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { hover_link } from '../../style/constants';
import { link_hover_border_distance, link_hover_border_size } from '../../style/Page';

const Centerize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  max-width: 1180px;
`;

const StyledArrow = styled.img`
  width: 2em;
`;

const ArrowContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  height: 2em;
  align-items: center;

  span {
    margin-left: 16px;
    margin-right: 16px;
  }

  img {
    transform: scale(-1, 1);
  }

  :hover {
    span {
      color: ${hover_link};
    }
  }
`;

const ArrowContainerLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  height: 2em;
  align-items: center;

  span {
    margin-left: 16px;
    margin-right: 16px;
  }

  :hover {
    span {
      color: ${hover_link};
    }
  }
`;

const StyledLinkLeft = styled(Link)`
  :hover {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const StyledLinkRight = styled(Link)`
  :hover {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const NextPrevious = ({next, previous}) => (
  <Centerize>
    <StyledContainer>
      {previous ? (<StyledLinkLeft to={previous.url}  onClick={() => window.scrollTo(0, 0)}>
        <ArrowContainerLeft>
          <StyledArrow
            src="./images/previous.svg"
          />
          <span><FormattedMessage id={previous.title} /></span>
        </ArrowContainerLeft>
      </StyledLinkLeft>) : <div></div>}
      {next && <StyledLinkRight to={next.url} onClick={() => window.scrollTo(0, 0)}>
        <ArrowContainerRight>
          <span><FormattedMessage id={next.title} /></span>
          <StyledArrow
            src="./images/previous.svg"
          />
        </ArrowContainerRight>
      </StyledLinkRight>}
    </StyledContainer>
  </Centerize>
);

export default NextPrevious;
