import styled from 'styled-components';
import { project_category_font, project_category_odd_color, secondary_color } from '../../style/constants';

const titleSpaces = '32px';

const Container = styled.div`
  width: 100%;
  ${({noMargin}) => noMargin ? "" : `padding-bottom: ${titleSpaces};`}

  h2 {
    width: 80%;
    max-width: 1180px;
    font-family: '${project_category_font}', sans-serif;
    font-weight: bold;
    color: ${secondary_color};
    font-size: 24px;
  }
`;

const CategoryTitle = ({ children, className, noMargin }) => (
  <Container className={className} noMargin={noMargin}><h2>{children}</h2></Container>
);

export default CategoryTitle;
