import { FormattedMessage, useIntl } from "react-intl"
import { secondary_color } from "../../../style/constants"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import { DottedLineDown } from "../../widgets/DottedLineDown"
import ListItem from "../../widgets/ListItem"
import Paragraph from "../../widgets/Paragraph"
import { SideBySide } from "./PrototypeDetails"

export const DottedBackground = ({ size }) => (
  <div className="background">
    <div className="columns" style={{ height: '100%' }}>
      <div className={`column is-${size ? size : 9}`}></div>
      <DottedLineDown className="column" />
    </div>
  </div>
)

const ResearchDetails = ({ flavor }) => {
  const intl = useIntl();
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle>
            <FormattedMessage
              id="research.category.title"
              defaultMessage="Research"
              description="research category title"
            />
          </CategoryTitle>
          <SideBySide>
            <section className="mb-6">
              <Paragraph>
                <FormattedMessage id="onboarding.research.description.1" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.research.identified.pain.points"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                    em: chunks => <u>{chunks}</u>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="onboarding.research.description.2" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.research.description.3"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </Paragraph>
            </section>
            <img
              style={{ border: `1px solid ${secondary_color}` }}
              src="./images/onboarding/UserFlow.png"
              alt={intl.formatMessage({ id: 'alt.onboarding.research.userflow' })}
            />
          </SideBySide>
        </div>
      </div>
    )
  }
  return (
    <TopOnTop>
      <DottedBackground size={10} />
      <div className="foreground columns mt-4">
        <div className="column"></div>
        <div className="column is-8">
          <CategoryTitle>
            <FormattedMessage
              id="research.category.title"
              defaultMessage="Research"
              description="research category title"
            />
          </CategoryTitle>
          <SideBySide>
            <section>
              <Paragraph>
                <FormattedMessage id="onboarding.research.description.1" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.research.identified.pain.points"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                    em: chunks => <u>{chunks}</u>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <ListItem>{chunks}</ListItem>
                  }}
                />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="onboarding.research.description.2" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage
                  id="onboarding.research.description.3"
                  values={{
                    strong: chunks => <strong>{chunks}</strong>,
                  }}
                />
              </Paragraph>
            </section>
            <img
              style={{ border: `1px solid ${secondary_color}` }}
              src="./images/onboarding/UserFlow.png"
              alt={intl.formatMessage({ id: 'alt.onboarding.research.userflow' })}
            />
          </SideBySide>
        </div>
        <div className="column"></div>
      </div>
    </TopOnTop>
  )
}

export default ResearchDetails
