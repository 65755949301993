import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Blob from '../components/widgets/Blob';
import BlobContainer from '../components/widgets/BlobContainer';
import Header from '../components/Header'
import { bulma_breakpoint_tablet, header_background_color, phone_width } from '../style/constants';
import Page from '../style/Page';
import Card from '../components/widgets/Card';
import MediaQuery from 'react-responsive';

const StyledBlobContainer = styled(BlobContainer)`
  z-index: 1;

  .blob.right {
    right: -6.5em;
    top: 20.5em;
    height: 10em;
    z-index: -1;
  }

  .blob.left {
    height: 13em;
    left: -5em;
    top: 7em;
  }

  @media screen and (max-width: ${phone_width}px) {
    .blob.left {
      left: -6em;
    }

    .blob.right {
      right: -8em;
      top: 20.5em;
      height: 10em;
    }
  }
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
`;

const Index = () => {
  const intl = useIntl();
  return (
    <Page colorlessFooter littlePadding>
      <StyledBlobContainer>
        <Blob page="home" position="left" />
        <Blob page="home" position="right" />
      </StyledBlobContainer>
      <Header
        noColor
        title={intl.formatMessage({
          id: 'home.page.title',
          defaultMessage: 'UX Designer / UX Writer',
          description: 'home page title',
        })}
        description={[
          intl.formatMessage({
            id: 'home.page.description.2',
          }),
          intl.formatMessage({
            id: 'home.page.description.3',
          })
        ]}
      />
      <StyledMain id="page-wrap" className="py-6">
        <MediaQuery minWidth={bulma_breakpoint_tablet}>
          <div className="columns">
            <div className="column is-1"></div>
            <div className="column tile is-ancestor is-10">
              <div className="tile is-parent is-vertical is-6">
                <Card
                  image="vignette_Photo_VolontariatBNP.png"
                  topics={['topic.design.sprint', 'topic.ux.design', 'topic.ui.design']}
                  title="volunteering.title.ongoing"
                  link="./volunteering"
                  color="rgb(112,193,179,0.30)"
                />
                <Card
                  image="vignette_whatsnew.png"
                  topics={['topic.ui.design']}
                  title="whatsnew.title.ongoing"
                  link="./whatsnew"
                  color={header_background_color}
                  big
                />
              </div>
              <div className="tile is-parent is-vertical is-6">
                <Card
                  image="vignette_3dstory.png"
                  topics={['topic.ux.design', 'topic.ux.writing']}
                  title="onboarding.title"
                  link="./onboarding"
                  color={header_background_color}
                  big
                />
                <Card
                  image="vignette_FlyUXPhoto.png"
                  topics={['topic.ux.design']}
                  title="flyux.title"
                  link="./fly"
                  color="rgb(112,193,179,0.30)"
                />
              </div>
            </div>
            <div className="column is-1"></div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
          <div className="tile is-ancestor mx-4">
            <div className="tile is-parent is-vertical is-6">
              <Card
                image="vignette_Photo_VolontariatBNP.png"
                topics={['topic.design.sprint', 'topic.ux.design', 'topic.ui.design']}
                title="volunteering.title.ongoing"
                link="./volunteering"
                color="rgb(112,193,179,0.30)"
              />
              <Card
                image="vignette_3dstory.png"
                topics={['topic.ux.design', 'topic.ux.writing']}
                title="onboarding.title"
                link="./onboarding"
                color={header_background_color}
              />
            </div>
            <div className="tile is-parent is-vertical is-6">
              <Card
                image="vignette_whatsnew.png"
                topics={['topic.ui.design']}
                title="whatsnew.title.ongoing"
                link="./whatsnew"
                color={header_background_color}
              />
              <Card
                image="vignette_FlyUXPhoto.png"
                topics={['topic.ux.design']}
                title="flyux.title"
                link="./fly"
                color="rgb(112,193,179,0.30)"
              />
            </div>
          </div>
        </MediaQuery>
      </StyledMain>
    </Page>
  );
};

export default Index;
