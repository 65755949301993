import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import ImageViewer from 'react-simple-image-viewer';
import Header from '../components/Header';
import MediaQuery from 'react-responsive';
import ProjectMain from '../components/layout/ProjectMain';
import Overview from '../components/Overview';
import CategoryTitle from '../components/widgets/CategoryTitle';
import IntroBar from '../components/widgets/IntroBar';
import OverviewModel from '../model/OverviewModel';
import { bulma_breakpoint_tablet, primary_color, responsive_table_and_less, secondary_color } from '../style/constants'
import Page from '../style/Page';
import TopOnTop from '../components/layout/TopOnTop';
import { HorizontalDottedLine, ProjectPart } from './onboarding';
import Project from '../components/Project';
import ListItem from '../components/widgets/ListItem';
import { DottedLineDown, DottedLineHorizontal, TabletDottedLineDown } from '../components/widgets/DottedLineDown';
import { SideBySide } from "../components/pages/onboarding/PrototypeDetails"
import Paragraph from '../components/widgets/Paragraph';
import ImagesMosaic, { ImagesMosaicBig } from '../components/widgets/ImagesMosaic';
import Image from '../components/widgets/Image';
import Takeaways from '../components/Takeaways';
import TakeawayModel from '../model/TakeawayModel';
import { DottedBackground } from '../components/pages/onboarding/ResearchDetails';
import { useCallback, useState } from 'react';
import NextPrevious from '../components/widgets/NextPrevious';

const FullDottedLineHorizontal = styled.div`
    border-top: 4px dotted ${primary_color}40;
    align-self: center;
`;

const VolunteeringProcessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas:
    'day1 day2 day3 day4 day5'
    't1 t2 t3 t4 t5'
    'line line line line line'
    'd1 d2 d3 d4 d5';
`;

const TabletVolunteeringProcessGrid = styled.div`
  display: grid;
  grid-template-columns: max-width auto;
  grid-template-rows: repeat(5, 1fr);
  grid-row-gap: 1.5em;

  grid-template-areas:
    "line day1"
    "line day2"
    "line day3"
    "line day4"
    "line day5"
`;

const Day = styled.div`
  color: ${secondary_color};
  text-transform: uppercase;
  justify-self: center;
`;

const ProcessStep = styled.div`
  font-weight: bold;
  justify-self: center;
`;

const BigDot = styled.span`
  align-self: center;

  justify-self: center;
  margin-top: -8px;
  color: ${primary_color};
`;

const ProcessList = styled.ul`
`

const TabletProcessDotsLineGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-areas:
    "s" "l" "l" "l" "l" "l" "l" "l" "l";
  justify-content: center;
`
const ProcessDotsLineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-areas:
    "s l l l l l l l l";
  align-content: center;
`

const TabletDots = () => (
  <TopOnTop style={{ gridArea: 'line' }}>
    <TabletProcessDotsLineGrid className="background">
      <div style={{ gridArea: 's' }}></div>
      <TabletDottedLineDown style={{ gridArea: 'l' }} />
      <div style={{ gridArea: 's' }}></div>
    </TabletProcessDotsLineGrid>
    <div className="foreground" style={{
      display: 'grid',
      gridTemplateRows: 'auto max-content auto auto max-content auto auto max-content auto auto max-content auto auto max-content auto',
    }}>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
    </div>
  </TopOnTop>
)

const Dots = () => (
  <TopOnTop style={{ gridArea: 'line' }}>
    <ProcessDotsLineGrid className="background">
      <div style={{ gridArea: 's' }}></div>
      <FullDottedLineHorizontal style={{ gridArea: 'l' }} />
      <div style={{ gridArea: 's' }}></div>
    </ProcessDotsLineGrid>
    <div className="foreground" style={{
      display: 'grid',
      gridTemplateColumns: 'auto max-content auto auto max-content auto auto max-content auto auto max-content auto auto max-content auto',
    }}>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
      <div></div>
      <BigDot className="is-size-1">•</BigDot>
      <div></div>
    </div>
  </TopOnTop>
)

const StepDescription = styled.div`
  justify-self: center;
`;

const StepDescriptionItem = styled(ListItem)`
  display: flex;
`

const VolunteeringProcess = ({ isTablet }) => (isTablet ? (
  <TabletVolunteeringProcessGrid>
    <TabletDots />
    <div>
      <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 1 }} /></Day>
      <ProcessStep><FormattedMessage id="volunteering.process.day.1.title" /></ProcessStep>
      <StepDescription>
        <FormattedMessage
          id="volunteering.process.day.1.description"
          values={{
            ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
            li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
          }}
        />
      </StepDescription>
    </div>
    <div>
      <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 2 }} /></Day>
      <ProcessStep><FormattedMessage id="volunteering.process.day.2.title" /></ProcessStep>
      <StepDescription>
        <FormattedMessage
          id="volunteering.process.day.2.description"
          values={{
            ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
            li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
          }}
        />
      </StepDescription>
    </div>
    <div>
      <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 3 }} /></Day>
      <ProcessStep><FormattedMessage id="volunteering.process.day.3.title" /></ProcessStep>
      <StepDescription>
        <FormattedMessage
          id="volunteering.process.day.3.description"
          values={{
            ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
            li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
          }}
        />
      </StepDescription>
    </div>
    <div>
      <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 4 }} /></Day>
      <ProcessStep><FormattedMessage id="volunteering.process.day.4.title" /></ProcessStep>
      <StepDescription>
        <FormattedMessage
          id="volunteering.process.day.4.description"
          values={{
            ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
            li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
          }}
        />
      </StepDescription>
    </div>
    <div>
      <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 5 }} /></Day>
      <ProcessStep><FormattedMessage id="volunteering.process.day.5.title" /></ProcessStep>
      <StepDescription>
        <FormattedMessage
          id="volunteering.process.day.5.description"
          values={{
            ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
            li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
          }}
        />
      </StepDescription>
    </div>
  </TabletVolunteeringProcessGrid>
) : (
  <VolunteeringProcessGrid>
    <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 1 }} /></Day>
    <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 2 }} /></Day>
    <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 3 }} /></Day>
    <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 4 }} /></Day>
    <Day><FormattedMessage id="volunteering.process.day" values={{ nb: 5 }} /></Day>
    <ProcessStep><FormattedMessage id="volunteering.process.day.1.title" /></ProcessStep>
    <ProcessStep><FormattedMessage id="volunteering.process.day.2.title" /></ProcessStep>
    <ProcessStep><FormattedMessage id="volunteering.process.day.3.title" /></ProcessStep>
    <ProcessStep><FormattedMessage id="volunteering.process.day.4.title" /></ProcessStep>
    <ProcessStep><FormattedMessage id="volunteering.process.day.5.title" /></ProcessStep>
    <Dots />
    <StepDescription>
      <FormattedMessage
        id="volunteering.process.day.1.description"
        values={{
          ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
          li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
        }}
      />
    </StepDescription>
    <StepDescription>
      <FormattedMessage
        id="volunteering.process.day.2.description"
        values={{
          ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
          li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
        }}
      />
    </StepDescription>
    <StepDescription>
      <FormattedMessage
        id="volunteering.process.day.3.description"
        values={{
          ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
          li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
        }}
      />
    </StepDescription>
    <StepDescription>
      <FormattedMessage
        id="volunteering.process.day.4.description"
        values={{
          ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
          li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
        }}
      />
    </StepDescription>
    <StepDescription>
      <FormattedMessage
        id="volunteering.process.day.5.description"
        values={{
          ul: (...chunks) => <ProcessList>{chunks}</ProcessList>,
          li: (chunks) => <StepDescriptionItem>{chunks}</StepDescriptionItem>
        }}
      />
    </StepDescription>
  </VolunteeringProcessGrid>
))

const Day1 = ({ isTablet }) => {
  const intl = useIntl();
  if (isTablet) {
    return (
      <div className="mx-6">
        <section className="mb-6">
          <CategoryTitle><FormattedMessage id="volunteering.day.1.title" /></CategoryTitle>
          <Paragraph><FormattedMessage id="volunteering.day.1.p1" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.1.p2" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.1.p3" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.1.p4" /></Paragraph>
        </section>
        <img
          style={{ border: `1px solid ${secondary_color}` }}
          src="./images/volunteering/day1.png"
          alt={intl.formatMessage({ id: 'alt.volunteering.day.1.image' })}
        />
      </div>
    );
  }
  return (
    <TopOnTop>
      <DottedBackground size={2} />
      <div className="columns foreground mt-4">
        <div className="column"></div>
        <div className="column is-9">
          <SideBySide className="foreground">
            <img
              style={{ border: `1px solid ${secondary_color}` }}
              src="./images/volunteering/day1.png"
              alt={intl.formatMessage({ id: 'alt.volunteering.day.1.image' })}
            />
            <section>
              <CategoryTitle><FormattedMessage id="volunteering.day.1.title" /></CategoryTitle>
              <Paragraph><FormattedMessage id="volunteering.day.1.p1" /></Paragraph>
              <Paragraph><FormattedMessage id="volunteering.day.1.p2" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
              <Paragraph><FormattedMessage id="volunteering.day.1.p3" /></Paragraph>
              <Paragraph><FormattedMessage id="volunteering.day.1.p4" /></Paragraph>
            </section>
          </SideBySide>
        </div>
        <div className="column is-2"></div>
      </div>
    </TopOnTop>
  )
}

const Day2 = ({ isTablet }) => {
  const intl = useIntl();
  if (isTablet) {
    return (
      <div className="mx-6 mt-6 mb-6">
        <section className="mb-6">
          <CategoryTitle><FormattedMessage id="volunteering.day.2.title" /></CategoryTitle>
          <Paragraph><FormattedMessage id="volunteering.day.2.p1" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.2.p2" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.2.p3" values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <ListItem>{chunks}</ListItem>,
          }} /></Paragraph>
        </section>
        <img
          style={{ border: `1px solid ${secondary_color}` }}
          src="./images/volunteering/day2.png"
          alt={intl.formatMessage({ id: 'alt.volunteering.day.2.image' })}
        />
      </div>
    );
  }
  return (
    <TopOnTop>
      <DottedBackground size={10} />
      <div className="columns foreground mt-4">
        <div className="column is-2"></div>
        <div className="column is-10">
          <SideBySide>
            <section>
              <CategoryTitle><FormattedMessage id="volunteering.day.2.title" /></CategoryTitle>
              <Paragraph><FormattedMessage id="volunteering.day.2.p1" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
              <Paragraph><FormattedMessage id="volunteering.day.2.p2" /></Paragraph>
              <Paragraph><FormattedMessage id="volunteering.day.2.p3" values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                ul: (...chunks) => <ul>{chunks}</ul>,
                li: (...chunks) => <ListItem>{chunks}</ListItem>,
              }} /></Paragraph>
            </section>
            <img
              style={{ border: `1px solid ${secondary_color}` }}
              src="./images/volunteering/day2.png"
              alt={intl.formatMessage({ id: 'alt.volunteering.day.2.image' })}
            />
          </SideBySide>
        </div>
      </div>
    </TopOnTop>
  )
}

const Day3 = ({ isTablet }) => {
  const intl = useIntl();
  if (isTablet) {
    return (
      <div className="mx-6 mt-6">
        <section className="mb-6">
          <CategoryTitle><FormattedMessage id="volunteering.day.3.title" /></CategoryTitle>
          <Paragraph><FormattedMessage id="volunteering.day.3.p1" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.3.p2"  values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
        </section>
        <img
          style={{ border: `1px solid ${secondary_color}` }}
          src="./images/volunteering/day3.png"
          alt={intl.formatMessage({ id: 'alt.volunteering.day.3.image' })}
        />
      </div>
    )  
  }
  return (
    <TopOnTop>
      <DottedBackground size={2} />
      <div className="columns foreground mt-4">
        <div className="column"></div>
        <div className="column is-9">
        <SideBySide>
          <img
            style={{ border: `1px solid ${secondary_color}` }}
            src="./images/volunteering/day3.png"
            alt={intl.formatMessage({ id: 'alt.volunteering.day.3.image' })}
          />
          <section>
            <CategoryTitle><FormattedMessage id="volunteering.day.3.title" /></CategoryTitle>
            <Paragraph><FormattedMessage id="volunteering.day.3.p1" /></Paragraph>
            <Paragraph><FormattedMessage id="volunteering.day.3.p2"  values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          </section>
        </SideBySide>
        </div>
        <div className="column is-2"></div>
      </div>
    </TopOnTop>
  )
}

const Day4 = ({ isTablet }) => {
  const intl = useIntl();
  if (isTablet) {
    return (
      <div className="mx-6 mb-6 mt-6">
        <section className="mb-6">
          <CategoryTitle><FormattedMessage id="volunteering.day.4.title" /></CategoryTitle>
          <Paragraph><FormattedMessage id="volunteering.day.4.p1" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.4.p2" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.4.p3" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.4.p4" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.4.p5" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
        </section>
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <img
            className="mb-4"
            style={{ maxWidth: '342px', border: '1px solid hsl(0deg, 0%, 29%)' }}
            src="./images/volunteering/day4-1.png"
            alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.1' })}
          />
          <img
            className="mb-4"
            style={{ maxWidth: '342px', border: '1px solid hsl(0deg, 0%, 29%)' }}
            src="./images/volunteering/day4-2.png"
            alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.2' })}
          />
          <img
            className="mb-4"
            style={{ maxWidth: '342px', border: '1px solid hsl(0deg, 0%, 29%)' }}
            src="./images/volunteering/day4-3.png"
            alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.3' })}
          />
          <img
            style={{ maxWidth: '342px', border: '1px solid hsl(0deg, 0%, 29%)' }}
            src="./images/volunteering/day4-4.png"
            alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.4' })}
          />
        </div>
      </div>
    );
  }
  return (
    <TopOnTop>
      <DottedBackground size={10} />
      <div className="columns foreground mt-4">
        <div className="column is-2"></div>
        <div className="column is-9">
        <SideBySide>
          <section>
            <CategoryTitle><FormattedMessage id="volunteering.day.4.title" /></CategoryTitle>
            <Paragraph><FormattedMessage id="volunteering.day.4.p1" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
            <Paragraph><FormattedMessage id="volunteering.day.4.p2" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
            <Paragraph><FormattedMessage id="volunteering.day.4.p3" /></Paragraph>
            <Paragraph><FormattedMessage id="volunteering.day.4.p4" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
            <Paragraph><FormattedMessage id="volunteering.day.4.p5" values={{ strong: (chunks) => <strong>{chunks}</strong> }} /></Paragraph>
          </section>
          <div className="quad-images">
            <img
              src="./images/volunteering/day4-1.png"
              alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.1' })}
            />
            <img
              src="./images/volunteering/day4-2.png"
              alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.2' })}
            />
            <img
              src="./images/volunteering/day4-3.png"
              alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.3' })}
            />
            <img
              src="./images/volunteering/day4-4.png"
              alt={intl.formatMessage({ id: 'alt.volunteering.day.4.image.4' })}
            />
          </div>
        </SideBySide>
        </div>
      </div>
    </TopOnTop>
  )
}

const Day5 = ({ isTablet, openImageViewer }) => {
  const intl = useIntl();
  if (isTablet) {
    return (
      <div className="mx-6">
        <section className="mb-6">
          <CategoryTitle><FormattedMessage id="volunteering.day.5.title" /></CategoryTitle>
          <Paragraph><FormattedMessage id="volunteering.day.5.p1" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.5.p2" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.5.p3" /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.5.p4" values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <ListItem>{chunks}</ListItem>,
          }} /></Paragraph>
          <Paragraph><FormattedMessage id="volunteering.day.5.p5" /></Paragraph>
        </section>
        <div className="is-flex is-flex-direction-column">
          <img
            className="mb-4"
            style={{ border: `1px solid ${secondary_color}` }}
            src="./images/volunteering/day5-main.png"
            alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.main' })}
          />
          <ImagesMosaicBig>
            <Image src="./images/volunteering/day5-1.png" alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.1' })} onClick={() => openImageViewer(0)} />
            <Image src="./images/volunteering/day5-2.png" alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.2' })} onClick={() => openImageViewer(1)} />
            <Image src="./images/volunteering/day5-3.png" alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.3' })} onClick={() => openImageViewer(2)} />
          </ImagesMosaicBig>
        </div>
      </div>
    );
  }
  return (
    <TopOnTop>
      <DottedBackground size={2} />
      <div className="columns foreground mt-4">
        <div className="column is-1"></div>
        <div className="column is-9">
          <TopOnTop>
            <SideBySide>
              <div className="is-flex is-flex-direction-column">
                <img
                  className="mb-4"
                  style={{ border: `1px solid ${secondary_color}` }}
                  src="./images/volunteering/day5-main.png"
                  alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.main' })}
                />
                <ImagesMosaicBig>
                  <Image src="./images/volunteering/day5-1.png" alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.1' })} onClick={() => openImageViewer(0)} />
                  <Image src="./images/volunteering/day5-2.png" alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.2' })} onClick={() => openImageViewer(1)} />
                  <Image src="./images/volunteering/day5-3.png" alt={intl.formatMessage({ id: 'alt.volunteering.day.5.image.3' })} onClick={() => openImageViewer(2)} />
                </ImagesMosaicBig>
              </div>
              <section>
                <CategoryTitle><FormattedMessage id="volunteering.day.5.title" /></CategoryTitle>
                <Paragraph><FormattedMessage id="volunteering.day.5.p1" /></Paragraph>
                <Paragraph><FormattedMessage id="volunteering.day.5.p2" /></Paragraph>
                <Paragraph><FormattedMessage id="volunteering.day.5.p3" /></Paragraph>
                <Paragraph><FormattedMessage id="volunteering.day.5.p4" values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  ul: (...chunks) => <ul>{chunks}</ul>,
                  li: (...chunks) => <ListItem>{chunks}</ListItem>,
                }} /></Paragraph>
                <Paragraph><FormattedMessage id="volunteering.day.5.p5" /></Paragraph>
              </section>
            </SideBySide>
          </TopOnTop>
        </div>
        <div className="column"></div>
      </div>
    </TopOnTop>
  )
}

const VolunteeringHeader = () => {
  const intl = useIntl()
  return (
    <Header
      title={intl.formatMessage({
        id: 'volunteering.title.ongoing',
      })}
      description={[
        intl.formatMessage({
          id: 'volunteering.project.description',
        })
      ]}
      noColor
    />
  )
}

const VolunteeringOverview = () => {
  const intl = useIntl()
  return (
    <div className="columns mt-6 mr-0">
      <div className="column"></div>
      <div className="column is-6">
        <Overview
          content={[
            new OverviewModel(
              intl.formatMessage({ id: 'project.context.title' }),
              () => (
                <>
                  <p><FormattedMessage id="volunteering.overview.context.p1" /></p>
                  <p><FormattedMessage id="volunteering.overview.context.p2" /></p>
                </>
              )
            ),
            new OverviewModel(
              intl.formatMessage({ id: 'project.problem.title' }),
              () => (
                <>
                  <p><FormattedMessage id="volunteering.overview.problem.p1" /></p>
                  <p><FormattedMessage
                    id="volunteering.overview.problem.p2"
                    values={{
                      strong: (...chunks) => <strong>{chunks}</strong>,
                    }}
                  /></p>
                  <p className="mt-4"><FormattedMessage id="volunteering.overview.problem.p3" values={{ br: () => <br /> }} /></p>
                  <p><FormattedMessage id="volunteering.overview.problem.p4" values={{ br: () => <br /> }} /></p>
                </>
              ), true
            )
          ]}
        />
      </div>
      <ProjectPart className="column is-4">
        <Project
          company={intl.formatMessage({ id: 'bnp.paribas.company.name' })}
          role={intl.formatMessage({ id: 'volunteering.role' })}
          tools={intl.formatMessage({ id: 'volunteering.tools' })}
          year={2022}
        />
      </ProjectPart>
      <div className="column"></div>
    </div>
  )
}

const VolunteeringProcessPart = ({ isTablet }) => (
  <div className={`columns mt-3 mb-6 ${isTablet ? '' : 'pb-6'}`}>
    <div className="column"></div>
    <div className="column is-10">
      <CategoryTitle className="mx-6">
        <FormattedMessage id="process.title" />
      </CategoryTitle>
      <VolunteeringProcess isTablet={isTablet} />
    </div>
    <div className="column"></div>
  </div>
)

const VolunteeringDesktop = () => {
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    './images/volunteering/day5-1-big.png',
    './images/volunteering/day5-2-big.png',
    './images/volunteering/day5-3-big.png',
  ];
  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Page>
        <VolunteeringHeader />
        <IntroBar img="volunteering/high_fid_2" alt="alt.volunteering.intro.image" />
        <ProjectMain>
          <VolunteeringOverview />
          <VolunteeringProcessPart />
          <HorizontalDottedLine from={2} noRight />
          <Day1 />
          <HorizontalDottedLine from={2} to={2} />
          <Day2 />
          <HorizontalDottedLine from={2} to={2} />
          <Day3 />
          <HorizontalDottedLine from={2} to={2} />
          <Day4 />
          <HorizontalDottedLine from={2} to={2} />
          <Day5 openImageViewer={openImageViewer} />
          <HorizontalDottedLine from={2} to={6} />
          <div className="columns">
            <div className="column"></div>
            <DottedLineDown className="column" style={{ height: '46px' }}></DottedLineDown>
          </div>
          <Takeaways
            title="volunteering.takeaway.title"
            items={[
              new TakeawayModel(undefined,
                intl.formatMessage({ id: 'volunteering.takeaway.p1' })
              ),
              new TakeawayModel(undefined,
                intl.formatMessage({ id: 'volunteering.takeaway.p2' })
              ),
              new TakeawayModel(undefined,
                intl.formatMessage({ id: 'volunteering.takeaway.p3' })
              ),
            ]}
          />
          <NextPrevious
            next={{
              title: 'onboarding.title',
              url: './onboarding',
            }}
          />
        </ProjectMain>
      </Page>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
    </>
  )
}

const VolunteeringTablet = () => {
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    './images/volunteering/day5-1-big.png',
    './images/volunteering/day5-2-big.png',
    './images/volunteering/day5-3-big.png',
  ];
  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Page>
        <VolunteeringHeader isTablet />
        <IntroBar isTablet img="volunteering/high_fid_2" alt="alt.volunteering.intro.image" />
        <ProjectMain>
          <VolunteeringOverview />
          <VolunteeringProcessPart isTablet />
          <Day1 isTablet />
          <Day2 isTablet />
          <Day3 isTablet />
          <Day4 isTablet />
          <Day5 isTablet openImageViewer={openImageViewer} />
          <Takeaways
            isTablet
            title="volunteering.takeaway.title"
            items={[
              new TakeawayModel(undefined,
                intl.formatMessage({ id: 'volunteering.takeaway.p1' })
              ),
              new TakeawayModel(undefined,
                intl.formatMessage({ id: 'volunteering.takeaway.p2' })
              ),
              new TakeawayModel(undefined,
                intl.formatMessage({ id: 'volunteering.takeaway.p3' })
              ),
            ]}
          />
          <NextPrevious
            next={{
              title: 'onboarding.title',
              url: './onboarding',
            }}
          />
        </ProjectMain>
      </Page>
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
        />
      )}
    </>
  )
}

const Volunteering = () => {
  return (
    <>
      <MediaQuery minWidth={bulma_breakpoint_tablet}>
        <VolunteeringDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={bulma_breakpoint_tablet - 1}>
        <VolunteeringTablet />
      </MediaQuery>
    </>
  )
}

export default Volunteering;
