import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { nav_font, responsive_desktop, responsive_table_and_less, selected_link, tablet_width } from '../../style/constants';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocale } from '../contexts/LocaleContext';

function selectedIf(value, pathname) {
  return value.includes(pathname) ? 'selected basic-link' : 'basic-link';
}

const LanguageList = styled.li`
  text-transform: uppercase;

  .french {
    margin-right: 8px;
  }

  .english {
    margin-left: 8px;
  }
`;

const StyledNav = styled.nav`
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-family: '${nav_font}', sans-serif;
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
`;

const StyledListItem = styled.li`
  ${responsive_table_and_less} {
    margin-right: 16px;
  }

  ${responsive_desktop} {
    margin-right: 48px;
  }

  .selected {
    padding-bottom: 8px;
    color: ${selected_link} !important;
  }
`;

const StyledLink = styled(Link)`
  color: black;
`;

const Nav = () => {
  const location = useLocation();
  const { setLocale } = useLocale();
  return (
    <MediaQuery minWidth={tablet_width}>
      <StyledNav>
        <StyledList>
          <StyledListItem>
            <StyledLink className={selectedIf(['/', '/onboarding', '/fly', '/whatsnew', '/volunteering'], location.pathname)} to="/">
              <FormattedMessage
                id="nav.work"
              />
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink className={selectedIf(['/about'], location.pathname)} to="/about">
              <FormattedMessage
                id="nav.about"
              />
            </StyledLink>
          </StyledListItem>
          <LanguageList>
            <StyledLink
              to={`${location.pathname}`}
              locale="fr"
              className="french"
              onClick={() => setLocale('fr')}
            >
              FR
            </StyledLink>
            /
            <StyledLink
              to={`${location.pathname}`}
              locale="en-US"
              className="english"
              onClick={() => setLocale('en')}
            >
              EN
            </StyledLink>
          </LanguageList>
        </StyledList>
      </StyledNav>
    </MediaQuery>
  );
};

export default Nav;
