export const BASIC = 'BASIC';
export const CUSTOMIZED = 'CUSTOMIZED';

export default class TakeawayModel {
  constructor(title, description, type) { // TODO: type is for retrocompatibility, it will ends to be all cutomizable
    this.title = title;
    this.description = description;
    this.type = type !== undefined ? type : BASIC;
  }
}
