import { FormattedMessage, useIntl } from "react-intl"
import { secondary_color } from "../../../style/constants"
import TextAndSideImage from "../../layout/TextAndSideImage"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import { DottedLineDown } from "../../widgets/DottedLineDown"
import ListItem from "../../widgets/ListItem"
import Paragraph from "../../widgets/Paragraph"
import SideImage from "../../widgets/SideImage"
import SideText from "../../widgets/SideText"
import { SideBySide } from "../onboarding/PrototypeDetails"

export const DottedBackground = ({ size }) => (
  <div className="background">
    <div className="columns" style={{ height: '100%' }}>
      <div className={`column is-${size ? size : 9}`}></div>
      <DottedLineDown className="column" />
    </div>
  </div>
)

const WireframesDetails = ({ flavor }) => {
  const intl = useIntl();
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle>
            <FormattedMessage
              id="wireframes.category.title"
            />
          </CategoryTitle>
          <SideBySide>
            <section className="mb-6">
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.1" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.2" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.3" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.4" />
              </Paragraph>
              <ul>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.4.a" /></ListItem>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.4.b" /></ListItem>
              </ul>
            </section>
            <img
              style={{ border: `1px solid ${secondary_color}` }}
              src="./images/whatsnew/wireframes_1.png"
              alt={intl.formatMessage({ id: 'alt.whatsnew.wireframes.1' })}
            />
          </SideBySide>
          <img
            style={{ border: `1px solid ${secondary_color}` }}
            src="./images/whatsnew/wireframes_2.png"
            alt={intl.formatMessage({ id: 'alt.whatsnew.wireframes.2' })}
          />
        </div>
      </div>
    )
  }
  return (
    <>
      <TopOnTop>
        <DottedBackground size={2} />
        <div className="foreground mt-4">
          <div className="columns">
            <div className="column"></div>
            <div className="column is-8">
              <CategoryTitle className="mx-3">
                <FormattedMessage
                  id="wireframes.category.title"
                />
              </CategoryTitle>
            </div>
            <div className="column"></div>
          </div>
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-7 mx-3">
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.1" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.2" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.3" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="whatsnew.wireframes.description.4" />
              </Paragraph>
              <ul>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.4.a" /></ListItem>
                <ListItem><FormattedMessage id="whatsnew.wireframes.description.4.b" /></ListItem>
              </ul>
            </div>
            <div className="column is-2"></div>
          </div>
          <div className="columns my-3">
            <div className="column"></div>
            <div className="column is-5">
              <img
                style={{ border: `1px solid ${secondary_color}` }}
                src="./images/whatsnew/wireframes_1.png"
                alt={intl.formatMessage({ id: 'alt.whatsnew.wireframes.1' })}
              />
            </div>
            <div className="column is-5">
              <img
                style={{ border: `1px solid ${secondary_color}` }}
                src="./images/whatsnew/wireframes_2.png"
                alt={intl.formatMessage({ id: 'alt.whatsnew.wireframes.2' })}
              />
            </div>
            <div className="column"></div>
          </div>
          <div className="columns my-2">
            <div className="column"></div>
          </div>
        </div>
      </TopOnTop>
    </>
  )
}

export default WireframesDetails
