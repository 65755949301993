const CookieBar = () => {
  return (
    <>
    {/*<CookieConsent enableDeclineButton>
      This website uses cookies to enhance the user experience.
    </CookieConsent>*/}
    </>
  );
};

export default CookieBar;
