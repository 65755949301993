import { useMemo, useState } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 48px;
  height: 48px;
  position: fixed;
  right: 32px;
  bottom: 32px;

  :hover {
    cursor: pointer;
  }

  z-index: 3;
`;

const GoToTop = () => {
  const [showGoToTop, setShowGoToTop] = useState(false);

  const listenToScroll = () => {
    let heightToShowFrom = 150;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToShowFrom && !showGoToTop) {
      setShowGoToTop(true);
    } else {
      setShowGoToTop(false);
    }
  };

useMemo(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  return (
    showGoToTop ? (
      <StyledImage
        src="./images/go-to-top.svg"
        onClick={() => window.scrollTo(0, 0)}
        style={{visibility: showGoToTop ? 'visible' : 'hidden'}}
      />
    ) : (<></>)
  );
};

export default GoToTop;
