import styled from 'styled-components';

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 3em;

  .star {
    margin: 16px;
  }

  .avatar {
    z-index: 6;
    width: 192px;
    height: 192px;
    border: 54px solid;
    border-image: url("./images/ellipse.svg") round;
    border-image-slice: 52.5;
    background-image: url("./images/avatar.png");
    background-position: center;
    background-size: 165px;
    background-repeat: no-repeat;
  }
`;

const Me = () => (
  <AvatarContainer>
    <div className="avatar" />
  </AvatarContainer>
);

export default Me;
