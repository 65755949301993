import styled from 'styled-components';
import { process_step_icon_size, process_step_title_font, process_step_title_size, process_step_underline_size } from '../../style/constants';
import ListItem from '../widgets/ListItem';

const AreaContainer = (number) => styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-areas:
    "image title"
    "image text";
  grid-area: step-${number};
`;

const StepIllustration = styled.img`
  width: ${process_step_icon_size};
  margin-right: 24px;
  grid-area: image;
`;

const StepTitle = styled.div`
  grid-area: title;

  h3 {
    font-family: '${process_step_title_font}', sans-serif;
    font-size: ${process_step_title_size};
    margin: 0px;
    margin-bottom: -16px;
  }
`;

const StepText = styled.ul`
  grid-area: text;
`;

const Underline = styled.img`
  width: ${process_step_underline_size};
`;

const FlexListItem = styled(ListItem)`
  display: flex;
`;

const Step = ({ index, type, title, content }) => {
  const StyledContainer = AreaContainer(index);
  return (
    <StyledContainer className={`process-step step-${index}`}>
      <StepTitle>
        <h3>{title}</h3>
        <Underline alt="underline" className="underline" src="./images/underline.svg" />
      </StepTitle>
      <StepText className="step-text">
        {content.map((text) => <FlexListItem><span>{text}</span></FlexListItem>)}
      </StepText>
    </StyledContainer>
  );
};

export default Step;
