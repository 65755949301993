import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"
import { responsive_desktop, responsive_table_and_less } from "../../../style/constants"
import TopOnTop from "../../layout/TopOnTop"
import CategoryTitle from "../../widgets/CategoryTitle"
import ListItem from "../../widgets/ListItem"
import Paragraph from "../../widgets/Paragraph"
import { DottedBackground } from "./ResearchDetails"

export const SideBySide = styled.div`
  ${responsive_desktop} {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 2em; //56px;
    margin-bottom: 3em;
  }

  .quad-images {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-template-columns: auto auto;
    grid-gap: 16px;

    img {
      border: 1px solid hsl(0deg, 0%, 29%);
    }
  }
`

const PrototypeDetails = ({ flavor }) => {
  const intl = useIntl();
  if (flavor === 'tablet') {
    return (
      <div className="columns mt-4 mr-0">
        <div className="column"></div>
        <div className="column mx-6">
          <CategoryTitle noMargin>
            <FormattedMessage
              id="prototype.category.title"
            />
          </CategoryTitle>
        </div>
        <div className="column mx-6">
          <Paragraph>
            <FormattedMessage
              id="onboarding.prototype.description.1"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="onboarding.prototype.description.2"
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                em: chunks => <u>{chunks}</u>,
                ul: chunks => <ul>{chunks}</ul>,
                li: chunks => <ListItem>{chunks}</ListItem>,
              }}
            />
          </Paragraph>
          <img
            className="mt-4"
            style={{ width: '100%' }}
            src="./images/onboarding/Onboarding1.png"
            alt={intl.formatMessage({ id: 'alt.onboarding.prototype.1' })}
          />
          <img
            className="mt-4"
            style={{ width: '100%' }}
            src="./images/onboarding/Onboarding2.png"
            alt={intl.formatMessage({ id: 'alt.onboarding.prototype.2' })}
          />
          <img
            className="mt-4"
            style={{ width: '100%' }}
            src="./images/onboarding/Onboarding3.png"
            alt={intl.formatMessage({ id: 'alt.onboarding.prototype.3' })}
          />
          <img
            className="mt-4"
            style={{ width: '100%' }}
            src="./images/onboarding/Onboarding4.png"
            alt={intl.formatMessage({ id: 'alt.onboarding.prototype.4' })}
          />
        </div>
        <div className="column"></div>
      </div>
    )
  }
  return (
    <TopOnTop>
      <DottedBackground size={2} />
      <div className="foreground mt-4">
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column is-8 pl-5">
            <CategoryTitle>
              <FormattedMessage
                id="prototype.category.title"
              />
            </CategoryTitle>
          </div>
          <div className="column is-2"></div>
        </div>
        <div className="columns">
          <div className="column"></div>
          <div className="column is-9">
            <SideBySide>
              <div className="quad-images" >
                <img
                  src="./images/onboarding/Onboarding1.png"
                  alt={intl.formatMessage({ id: 'alt.onboarding.prototype.1' })}
                />
                <img
                  src="./images/onboarding/Onboarding2.png"
                  alt={intl.formatMessage({ id: 'alt.onboarding.prototype.2' })}
                />
                <img
                  src="./images/onboarding/Onboarding3.png"
                  alt={intl.formatMessage({ id: 'alt.onboarding.prototype.3' })}
                />
                <img
                  src="./images/onboarding/Onboarding4.png"
                  alt={intl.formatMessage({ id: 'alt.onboarding.prototype.4' })}
                />
              </div>
              <section>
                <Paragraph>
                  <FormattedMessage
                    id="onboarding.prototype.description.1"
                  />
                </Paragraph>
                <Paragraph>
                  <FormattedMessage
                    id="onboarding.prototype.description.2"
                    values={{
                      strong: chunks => <strong>{chunks}</strong>,
                      em: chunks => <u>{chunks}</u>,
                      ul: chunks => <ul>{chunks}</ul>,
                      li: chunks => <ListItem>{chunks}</ListItem>,
                    }}
                  />
                </Paragraph>
              </section>
            </SideBySide>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </TopOnTop>
  )
}

export default PrototypeDetails
