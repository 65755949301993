import HCaptcha from '@hcaptcha/react-hcaptcha';
import styled from 'styled-components';
import { useLocale } from '../contexts/LocaleContext';

const Container = styled.div`
  margin-bottom: 8px;
`;

const Captcha = () => {
  const { locale } = useLocale();
  return (
    <Container>
      <HCaptcha
        languageOverride={locale}
        sitekey="70dbd25a-13f5-4919-a058-95e4f08cace7"
        onVerify={(token) => console.log("verified ", token)}
      />
    </Container>
  );
};

export default Captcha;
