import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Header from '../components/Header';
import Blob from '../components/widgets/Blob';
import BlobContainer from '../components/widgets/BlobContainer';
import { phone_width } from '../style/constants';
import Page from '../style/Page';

const StyledBlobContainer = styled(BlobContainer)`
  z-index: 1;

  .blob.right {
    right: -6.5em;
    top: 20.5em;
    height: 10em;
    z-index: -1;
  }

  .blob.left {
    height: 13em;
    left: -5em;
    top: 7em;
  }

  @media screen and (max-width: ${phone_width}px) {
    .blob.left {
      left: -6em;
    }

    .blob.right {
      right: -8em;
      top: 20.5em;
      height: 10em;
    }
  }
`;

const Privacy = () => {
  const intl = useIntl();
  return (
    <Page>
      <StyledBlobContainer>
        <Blob page="home" position="left" />
        <Blob page="home" position="right" />
      </StyledBlobContainer>
      <Header
        title={intl.formatMessage({
          id: 'privacy.page.title',
          defaultMessage: 'UX Designer / UX Writer',
          description: 'home page title',
        })}
        description={[
          intl.formatMessage({
            id: 'privacy.page.description',
            values: {
              p: chunks => <p>{chunks}</p>,
            },
          }),
        ]}
      />
    </Page>
  );
};

export default Privacy;
