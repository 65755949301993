import styled from 'styled-components';
import { phone_width, responsive_desktop, responsive_table_and_less, tablet_width } from '../../style/constants';

const TextAndSideImage = styled.div`
  ${responsive_desktop} {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 56px;

    ${({ invert }) => invert && 'grid-template-areas: "image text";'}

    ${({ verticalAlign }) => verticalAlign && `
      .side-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    `}
  }

  ${responsive_table_and_less} {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
  }
`;

export default TextAndSideImage;
