import { FormattedMessage } from "react-intl";

const Tag = ({ children }) => (
    <span
        className="tag is-danger is-light"
        style={{
            marginRight: '8px',
            marginBottom: '8px',
        }}
    >
        <FormattedMessage id={children} />
    </span>
)

export default Tag;
