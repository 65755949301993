import { useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import * as yup from 'yup';
import { secondary_color } from '../../../style/constants';
import Button from '../../widgets/Button';
import Captcha from '../../widgets/Captcha';

const FormGrid = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormFieldLabel = styled.div`
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const FormFieldContainer = styled.div`
  font-size: 0.8em;
  margin-top: 0px;
  margin-bottom: 8px;
  color: red;
  font-style: italic;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FormField = styled.input`
  line-height: 1.6em;
  padding: 8px 16px;
  min-height: 44px;
  max-width: 400px;
  border: unset;
  border-radius: 4px;
  outline-color: ${secondary_color};
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  margin-bottom: 8px;

  ${({ error }) => error ? `
    border: 1px solid red;
    margin-bottom: 0px;
  ` : ''}
`;

const FormTextArea = styled.textarea`
  line-height: 1.6em;
  padding: 8px 16px;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: ${secondary_color};
  background-color: white;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  margin-bottom: 16px;

  ${({ error }) => error ? `
    border: 1px solid red;
    margin-bottom: 0px;
  ` : ''}
`;

const FormNameLabel = styled(FormFieldLabel)`grid-area: name-label;`;
const FormNameField = styled(FormField)`grid-area: name-field;`;
const FormEmailLabel = styled(FormFieldLabel)`grid-area: email-label;`;
const FormEmailField = styled(FormField)`grid-area: email-field;`;
const FormSubjectLabel = styled(FormFieldLabel)`grid-area: subject-label;`;
const FormSubjectField = styled(FormField)`grid-area: subject-field;`;
const FormMessageLabel = styled(FormFieldLabel)`grid-area: message-label;`;
const FormMessageField = styled(FormTextArea)`grid-area: message-field;`;

const Attention = styled.img`
  width: 1.5em;
  height: intrinsic;
  margin-right: 8px;
`;

const FormFieldError = ({ children }) => (
  <FormFieldContainer>
    <Attention alt="caution" src="./images/attention.svg" />
    {children}
  </FormFieldContainer>
);

const Confidentiality = styled.div`
  margin-bottom: 16px;

  input {
    margin-right: 8px;
  }
`;

const ContactForm = () => {
  const schema = yup.string().email();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [consentment, setConsentment] = useState(false);
  const [consentmentError, setConsentmentError] = useState(null);

  const nameError = useMemo(() => {
    if (name === undefined || (name !== null && name.length === 0)) {
      return 'form.required';
    }
    return false;
  }, [name]);

  const emailError = useMemo(() => {
    if (email === undefined || (email !== null && email.length === 0)) {
      return 'form.required';
    } else if (email !== null && !schema.isValidSync(email)) {
      return 'form.email.invalid';
    }
    return false;
  }, [email, schema]);

  const subjectError = useMemo(() => {
    if (subject === undefined || (subject !== null && subject.length === 0)) {
      return 'form.required';
    }
  }, [subject]);

  const messageError = useMemo(() => {
    if (message === undefined || (message !== null && message.length === 0)) {
      return 'form.required';
    }
  }, [message]);

  const formRef = useRef(null);

  return (
    <FormGrid
      ref={formRef}
      method="post"
      name="contact_form"
      action="formmail.php"
    >
      <FormNameLabel><FormattedMessage id="form.field.name" /></FormNameLabel>
      <FormNameField onChange={(e) => {
          console.log(e.target.value);
          setName(e.target.value);
        }}
        value={name}
        type="text"
        name="name"
        error={nameError}
      />
      {nameError && <FormFieldError><FormattedMessage id={nameError} /></FormFieldError>}
      <FormEmailLabel><FormattedMessage id="form.field.email" /></FormEmailLabel>
      <FormEmailField onChange={(e) => {
          setEmail(e.target.value);
        }}
        value={email}
        type="email"
        name="email"
        error={emailError}
      />
      {emailError && <FormFieldError><FormattedMessage id={emailError} /></FormFieldError>}
      <FormSubjectLabel><FormattedMessage id="form.field.subject" /></FormSubjectLabel>
      <FormSubjectField onChange={(e) => {
          setSubject(e.target.value);
        }}
        value={subject}
        type="text"
        name="subject"
        error={subjectError}
      />
      {subjectError && <FormFieldError><FormattedMessage id={subjectError} /></FormFieldError>}
      <FormMessageLabel><FormattedMessage id="form.field.message" /></FormMessageLabel>
      <FormMessageField
        rows={8}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        error={messageError}
        name="message"
      />

      {messageError && <FormFieldError><FormattedMessage id={messageError} /></FormFieldError>}
      <Captcha />
      <Confidentiality>
        <input
          type="checkbox"
          name="confidentiality"
          onClick={() => { setConsentment(!consentment); setConsentmentError(null); }}
          value={consentment}
        />
        <span>
          <FormattedMessage
            id="confidentiality.checkbox"
            defaultMessage="Je consens à ce que mes données personnelles soient utilisées conformément à la "
          />
          <a href="/confidentiality" style={{textTransform: 'lowercase'}}>
            <FormattedMessage
              id="confidentiality.link"
              defaultMessage="Politique de confidentialité du site"
            />
          </a>
        </span>
      </Confidentiality>
      {consentmentError && <span style={{ color: 'red', marginTop: '-16px', marginBottom: '16px' }}><FormattedMessage
        id="confidentiality.checkbox.error"
        defaultMessage="Veuillez consentir au traitement de vos données personnelles"
      /></span>}
      
      <Button shrink onClick={() => {
        if (!consentment) {
          setConsentmentError(true);
        } else {
          formRef.current.submit();
        }
      }}>
        <FormattedMessage id="form.submit" />
      </Button>
    </FormGrid>
  );
};

export default ContactForm;
